import React from 'react';
import { useParams, Navigate, Link } from 'react-router-dom';
import { MapPin, Phone, Globe, Calendar, Share2, MessageCircle, Mail, Facebook, Instagram } from 'lucide-react';
import { Card } from '../components/ui/Card';
import { Button } from '../components/ui/Button';
import { useRestaurant } from '../hooks/useRestaurant';
import { useRestaurantEvents } from '../hooks/useRestaurantEvents';
import { EventCard } from '../components/events/EventCard';
import { PartnerCard } from '../components/ui/PartnerCard';

function getMapLinks(lat: number | undefined, long: number | undefined, address: string | undefined) {
  if (!lat || !long || !address) return null;
  const encodedAddress = encodeURIComponent(address);
  return {
    google: `https://www.google.com/maps/search/?api=1&query=${lat},${long}`,
    apple: `http://maps.apple.com/?q=${encodedAddress}&ll=${lat},${long}`
  };
}

export function RestaurantDetailsPage() {
  const { restaurantId } = useParams<{ restaurantId: string }>();
  console.log('Restaurant ID from URL:', restaurantId);
  
  if (!restaurantId) {
    console.error('No restaurant ID provided');
    return <Navigate to="/restaurants" replace />;
  }

  const { restaurant, loading, error } = useRestaurant(restaurantId);
  const { events, loading: eventsLoading } = useRestaurantEvents(restaurantId);

  // Debug logging
  console.log('Restaurant Details State:', {
    restaurantId,
    restaurant,
    loading,
    error,
    events,
    eventsLoading
  });

  const shareLinks = [
    {
      name: 'iMessage',
      icon: <MessageCircle className="h-5 w-5" />,
      url: `sms:&body=Check out ${restaurant?.name} on Try Everything: ${window.location.href}`
    },
    {
      name: 'WhatsApp',
      icon: (
        <svg viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5">
          <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347"/>
        </svg>
      ),
      url: `https://wa.me/?text=${encodeURIComponent(`Check out ${restaurant?.name} on Try Everything: ${window.location.href}`)}`
    },
    {
      name: 'Instagram',
      icon: <Instagram className="h-5 w-5" />,
      url: `https://www.instagram.com/share?url=${encodeURIComponent(window.location.href)}`
    },
    {
      name: 'Facebook',
      icon: <Facebook className="h-5 w-5" />,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`
    },
    {
      name: 'Email',
      icon: <Mail className="h-5 w-5" />,
      url: `mailto:?subject=${encodeURIComponent(`Check out ${restaurant?.name} on Try Everything`)}&body=${encodeURIComponent(`I found this amazing restaurant: ${window.location.href}`)}`
    }
  ];

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  if (error || !restaurant) {
    console.error('Failed to load restaurant:', error);
    return <Navigate to="/restaurants" replace />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-8">
          {/* Banner Image */}
          <img 
            src={restaurant.imageUrl} 
            alt={restaurant.name}
            className="w-full aspect-[16/9] object-cover rounded-lg mb-8"
          />
          
          {/* Restaurant Info Card */}
          <Card className="mb-8">
            <div className="p-6">
              <h1 className="text-3xl font-bold text-gray-900 mb-4">
                {restaurant.name}
              </h1>
              
              <p className="text-gray-600 mb-8">
                {restaurant.description}
              </p>

              <div className="space-y-4 text-gray-600">
                <div className="flex flex-wrap gap-x-8 gap-y-2">
                  <div className="flex items-center">
                    <MapPin className="h-5 w-5 mr-2" />
                    <span>{restaurant.address}</span>
                  </div>
                  
                  {restaurant.phone && (
                    <div>
                      <a 
                        href={`tel:${restaurant.phone}`}
                        className="hover:text-purple-600"
                      >
                        {restaurant.phone}
                      </a>
                    </div>
                  )}
                  
                  <div className="flex items-center gap-4">
                    <span>
                      <span className="text-yellow-400">★</span>
                      <span className="ml-1">{restaurant.rating.toFixed(1)}</span>
                    </span>
                    <span>{restaurant.cuisine}</span>
                    <span>{restaurant.priceRange}</span>
                  </div>
                </div>

                {restaurant.website && (
                  <a 
                    href={restaurant.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block text-purple-600 hover:text-purple-700 mt-2"
                  >
                    Visit Website →
                  </a>
                )}
              </div>
            </div>

            {/* Map */}
            {restaurant.lat && restaurant.long && restaurant.address && (
              <div className="h-[300px] relative">
                <iframe
                  src={`https://www.openstreetmap.org/export/embed.html?bbox=${(restaurant.long - 0.01).toFixed(6)},${(restaurant.lat - 0.01).toFixed(6)},${(restaurant.long + 0.01).toFixed(6)},${(restaurant.lat + 0.01).toFixed(6)}&layer=mapnik&marker=${restaurant.lat.toFixed(6)},${restaurant.long.toFixed(6)}`}
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  className="absolute inset-0 w-full h-full"
                ></iframe>
                <div className="absolute bottom-0 right-0 p-4 bg-white/80 backdrop-blur-sm rounded-tl-lg flex gap-4">
                  <a
                    href={getMapLinks(restaurant.lat, restaurant.long, restaurant.address)?.google}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-purple-600 hover:text-purple-700 flex items-center gap-1"
                  >
                    <MapPin className="h-4 w-4" />
                    Open in Google Maps
                  </a>
                  <a
                    href={getMapLinks(restaurant.lat, restaurant.long, restaurant.address)?.apple}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-purple-600 hover:text-purple-700 flex items-center gap-1"
                  >
                    <MapPin className="h-4 w-4" />
                    Open in Apple Maps
                  </a>
                </div>
              </div>
            )}
          </Card>

          {/* Upcoming Events */}
          <section>
            <h2 className="text-2xl font-semibold mb-6">Upcoming Events</h2>
            {eventsLoading ? (
              <div className="animate-pulse space-y-4">
                {[1, 2].map((i) => (
                  <div key={i} className="h-48 bg-gray-200 rounded-lg" />
                ))}
              </div>
            ) : events && events.length > 0 ? (
              <div className="space-y-4">
                {events.map((event) => (
                  <EventCard key={event.$id} event={event} />
                ))}
              </div>
            ) : (
              <Card>
                <div className="p-6 text-center text-gray-500">
                  <Calendar className="w-12 h-12 mx-auto mb-4 text-gray-400" />
                  <h3 className="text-lg font-medium mb-2">No Upcoming Events</h3>
                  <p>Check back later for new events at this restaurant.</p>
                </div>
              </Card>
            )}
          </section>
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          <Card>
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-medium text-gray-900">Share with Friends</h3>
                <Share2 className="h-5 w-5 text-gray-400" />
              </div>
              <div className="grid grid-cols-5 gap-4">
                {shareLinks.map(platform => (
                  <button
                    key={platform.name}
                    onClick={() => window.open(platform.url, '_blank')}
                    className="flex flex-col items-center gap-2 text-gray-600 hover:text-purple-600 transition-colors"
                  >
                    {platform.icon}
                    <span className="text-xs">{platform.name}</span>
                  </button>
                ))}
              </div>
            </div>
          </Card>
          
          {/* Add Partner Card */}
          <PartnerCard />
        </div>
      </div>
    </div>
  );
}