import { useState, useEffect } from 'react';
import { account } from '../lib/appwrite';
import { ID, Models } from 'appwrite';

export function useAuth() {
  const [user, setUser] = useState<Models.User<Models.Preferences> | null>(null);
  const [session, setSession] = useState<Models.Session | null>(null);
  const [jwt, setJwt] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    async function initAuth() {
      try {
        // Get current session
        const currentSession = await account.getSession('current');
        if (!mounted) return;
        setSession(currentSession);

        // Create new JWT
        const token = await account.createJWT();
        if (!mounted) return;
        setJwt(token.jwt);

        // Get user
        const currentUser = await account.get();
        if (!mounted) return;
        setUser(currentUser);
      } catch (error) {
        console.error('Auth error:', error);
        if (!mounted) return;
        setUser(null);
        setSession(null);
        setJwt(null);
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    initAuth();

    return () => {
      mounted = false;
    };
  }, []);

  const signUp = async (email: string, password: string, name: string) => {
    try {
      // Create user in Appwrite
      const user = await account.create(ID.unique(), email, password, name);

      // Send welcome email
      await fetch('/.netlify/functions/send-welcome-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          email,
          name 
        })
      });

      // Continue with login...
    } catch (error) {
      console.error('Signup failed:', error);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await account.deleteSession('current');
      setUser(null);
      setSession(null);
      setJwt(null);
      window.location.href = '/';
    } catch (error) {
      console.error('Sign out failed:', error);
      throw error;
    }
  };

  return {
    user,
    session,
    jwt,
    loading,
    isAuthenticated: !!user && !!session && !!jwt,
    signUp,
    signOut
  };
}