import React, { useEffect, useState } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import { Client, Functions, Models } from 'appwrite';
import { CheckCircle } from 'lucide-react';
import { Button } from '../components/ui/Button';
import { format } from 'date-fns';
import { bookingService } from '../services/booking';
import { useAuth } from '../hooks/useAuth';
import type { Booking, Event } from '../types/database';

// Add type definition for function execution
interface ExtendedExecution extends Models.Execution {
  response: string;
}

// Add type definition for user
interface AppwriteUser extends Models.User<Models.Preferences> {
  $id: string;
}

export function BookingConfirmationPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const sessionId = searchParams.get('session_id');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [booking, setBooking] = useState<Booking | null>(null);
  const [event, setEvent] = useState<Event | null>(null);
  const [bookingComplete, setBookingComplete] = useState(false);
  const { user, jwt, loading: authLoading } = useAuth() as { user: AppwriteUser | null, jwt: string | null, loading: boolean };

  useEffect(() => {
    // Don't start verification until auth is loaded
    if (authLoading) return;

    // Skip if booking is already complete
    if (bookingComplete) return;

    async function verifyPaymentAndCreateBooking() {
      if (!sessionId || !user) {
        setError('Missing required data');
        setLoading(false);
        return;
      }

      try {
        // 1. Verify payment with Stripe
        console.log('Verifying payment...');
        const verifyResponse = await fetch('/.netlify/functions/verifyPayment', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sessionId })
        });

        const { success, data } = await verifyResponse.json();
        console.log('Payment verification response:', { success, data });

        if (!success) {
          throw new Error('Payment verification failed');
        }

        // Extract customer details
        const customerDetails = {
          email: data.customer_email || data.metadata?.customer_email,
          phone: data.customer_phone || data.metadata?.customer_phone
        };

        // Store these details for later use
        const eventDetails = {
          title: data.event_title || data.metadata?.event_title,
          date: data.event_date || data.metadata?.event_date,
          time: data.event_time || data.metadata?.event_time,
          restaurantName: data.restaurant_name || data.metadata?.restaurant_name,
          restaurantAddress: data.restaurant_address || data.metadata?.restaurant_address
        };

        // 2. Create booking
        console.log('Creating booking...');
        const newBooking = await bookingService.createBooking(
          data.eventId,
          user.$id,
          parseInt(data.quantity),
          data.amount / 100,
          sessionId
        );
        console.log('Booking created:', newBooking);

        // Store the booking even before event update
        setBooking(newBooking);

        // 3. Update event using Appwrite Function
        console.log('Updating event registration...');
        const client = new Client()
          .setEndpoint(import.meta.env.VITE_APPWRITE_ENDPOINT)
          .setProject(import.meta.env.VITE_APPWRITE_PROJECT_ID);
        
        const functions = new Functions(client);
        
        const updateResult = await functions.createExecution(
          '676ce7f0000ef849d291',
          JSON.stringify({
            eventId: data.eventId,
            quantity: parseInt(data.quantity),
            userId: user?.$id,
            jwt: jwt
          })
        ) as ExtendedExecution;

        console.log('Event update response:', updateResult);

        // Parse the function response
        let updatedEvent;
        try {
          const functionResponse = JSON.parse(updateResult.response);
          console.log('Parsed function response:', functionResponse);

          if (!functionResponse.success) {
            throw new Error(functionResponse.message || 'Event update failed');
          }

          updatedEvent = functionResponse.event;
          console.log('Updated event:', updatedEvent);
          setEvent(updatedEvent);
        } catch (parseError) {
          console.error('Failed to parse or validate function response:', parseError);
          console.log('Raw response:', updateResult);
          
          // Use the data from the payment verification as fallback
          const fallbackEvent: Event = {
            $id: data.eventId,
            title: eventDetails.title,
            date: eventDetails.date,
            time: eventDetails.time,
            description: '',
            restaurantId: '',
            price: data.amount / 100,
            capacity: 0,
            registered: 0,
            imageUrl: '',
            city: '',
            status: 'published' as const,
            restaurant: {
              name: eventDetails.restaurantName,
              address: eventDetails.restaurantAddress
            },
            createdAt: '',
            updatedAt: '',
            $createdAt: '',
            $updatedAt: '',
            $collectionId: import.meta.env.VITE_APPWRITE_EVENTS_COLLECTION_ID,
            $databaseId: import.meta.env.VITE_APPWRITE_DATABASE_ID,
            $permissions: [],
            userId: ''
          };
          console.log('Using fallback event data:', fallbackEvent);
          setEvent(fallbackEvent);
        }

        // 4. Send notifications (even if event update failed)
        if (newBooking) {
          try {
            console.log('Sending notifications with payload:', JSON.stringify({
              booking: newBooking,
              event: updatedEvent,
              user: { 
                email: customerDetails.email,
                phone: customerDetails.phone
              }
            }, null, 2));

            const notificationPayload = {
              booking: {
                eventId: newBooking.eventId,
                userId: newBooking.userId,
                quantity: newBooking.quantity,
                totalAmount: newBooking.totalAmount,
                status: newBooking.status
              },
              event: {
                title: updatedEvent?.title || eventDetails.title,
                date: updatedEvent?.date || eventDetails.date,
                time: updatedEvent?.time || eventDetails.time,
                restaurant: updatedEvent?.restaurant || {
                  name: eventDetails.restaurantName,
                  address: eventDetails.restaurantAddress
                }
              },
              user: { 
                email: customerDetails.email,
                phone: customerDetails.phone
              },
              notifyBy: ['email']
            };

            // Log the exact data we're sending
            console.log('Notification request payload:', JSON.stringify(notificationPayload, null, 2));

            // Add validation with detailed logging
            if (!customerDetails.email) {
              console.error('Missing customer email. Customer details:', JSON.stringify(customerDetails, null, 2));
              throw new Error('Customer email is required for booking confirmation');
            }

            if (!notificationPayload.event.title) {
              console.error('Missing event title. Event details:', JSON.stringify({
                updatedEvent: updatedEvent,
                eventDetails: eventDetails,
                finalTitle: notificationPayload.event.title
              }, null, 2));
              throw new Error('Event title is required for booking confirmation');
            }

            // Log the final payload for debugging
            console.log('Final notification payload:', JSON.stringify({
              hasEmail: !!customerDetails.email,
              emailValue: customerDetails.email,
              hasEventTitle: !!notificationPayload.event.title,
              eventTitleValue: notificationPayload.event.title,
              fullPayload: notificationPayload
            }, null, 2));

            const notificationResponse = await fetch('/.netlify/functions/send-notifications', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(notificationPayload)
            });

            if (!notificationResponse.ok) {
              throw new Error(`Notification request failed with status ${notificationResponse.status}`);
            }

            const notificationResult = await notificationResponse.json();
            console.log('Notification response:', notificationResult);
          } catch (notificationError) {
            console.error('Failed to send notifications:', notificationError);
            // Don't throw here, we want to show success even if notifications fail
          }
        }

        setBookingComplete(true);
        setLoading(false);
      } catch (err) {
        console.error('Booking confirmation error:', err);
        setError(err instanceof Error ? err.message : 'Failed to confirm booking');
        setLoading(false);
        // Don't clear booking/event state here
      }
    }

    verifyPaymentAndCreateBooking();
  }, [sessionId, user, jwt, authLoading, bookingComplete]);

  if (!sessionId) {
    return (
      <div className="max-w-2xl mx-auto px-4 py-8 text-center">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          Invalid session. Please try booking again.
        </div>
        <Button onClick={() => navigate('/events')}>
          Return to Events
        </Button>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#29303D]"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-2xl mx-auto px-4 py-8 text-center">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          {error}
        </div>
        <Button onClick={() => navigate('/events')}>
          Return to Events
        </Button>
      </div>
    );
  }

  if (!booking || !event) {
    return (
      <div className="max-w-2xl mx-auto px-4 py-8 text-center">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          Booking not found
        </div>
        <Button onClick={() => navigate('/events')}>
          Return to Events
        </Button>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto px-4 py-8">
      <div className="text-center mb-8">
        <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-green-100 mb-4">
          <CheckCircle className="w-8 h-8 text-green-600" />
        </div>
        <h1 className="text-2xl font-bold text-gray-900 mb-2">Booking Confirmed!</h1>
        <p className="text-gray-600">
          Thank you for your booking. A confirmation email has been sent to your email address.
        </p>
      </div>

      <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Event Details</h2>
          <div className="space-y-4">
            <div>
              <h3 className="font-medium text-gray-900">Event</h3>
              <p className="text-gray-600">{event.title}</p>
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Date & Time</h3>
              <p className="text-gray-600">
                {format(new Date(event.date), 'MMMM d, yyyy')} at {event.time}
              </p>
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Location</h3>
              <p className="text-gray-600">{event.restaurant?.name}</p>
              <p className="text-gray-600">{event.restaurant?.address}</p>
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Number of Tickets</h3>
              <p className="text-gray-600">{booking.quantity}</p>
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Total Amount</h3>
              <p className="text-gray-600">${booking.totalAmount.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center space-x-4">
        <Button onClick={() => navigate('/profile/bookings')}>
          View My Bookings
        </Button>
        <Button variant="outline" onClick={() => navigate('/events')}>
          Browse More Events
        </Button>
      </div>
    </div>
  );
} 