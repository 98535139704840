export const appwriteConfig = {
  endpoint: import.meta.env.VITE_APPWRITE_ENDPOINT,
  projectId: import.meta.env.VITE_APPWRITE_PROJECT_ID,
  databaseId: import.meta.env.VITE_APPWRITE_DATABASE_ID,
  usersCollectionId: import.meta.env.VITE_APPWRITE_USERS_COLLECTION_ID,
  eventsCollectionId: import.meta.env.VITE_APPWRITE_EVENTS_COLLECTION_ID,
  restaurantsCollectionId: import.meta.env.VITE_APPWRITE_RESTAURANTS_COLLECTION_ID,
  bookingsCollectionId: import.meta.env.VITE_APPWRITE_BOOKINGS_COLLECTION_ID,
  reviewsCollectionId: import.meta.env.VITE_APPWRITE_REVIEWS_COLLECTION_ID,
  userAvatarsBucketId: '677afe0600157254b0df',
}; 