import React from 'react';
import mananImage from '../assets/team/manan.png';
import { Linkedin, Instagram } from 'lucide-react';
// Import Mat's image once it's added
// import matImage from '../assets/team/mat.jpg';

export function AboutPage() {
  const leadershipTeam = [
    {
      name: "Manan Patel",
      role: "CEO, Co-Founder",
      image: mananImage,
      socialLink: "https://www.linkedin.com/in/mananpatel95/",
      socialIcon: <Linkedin className="h-5 w-5" />,
      bio: "With a background in software engineering and product development, Manan leads the company and the technical vision of TryEverything. His expertise in building scalable platforms ensures a seamless experience for our community of food enthusiasts."
    },
    {
      name: "Mat Gene",
      role: "COO, Co-Founder",
      // Temporarily use a placeholder until Mat's image is added
      image: "https://placehold.co/400x400",
      socialLink: "https://www.instagram.com/bananadaipaidong/",
      socialIcon: <Instagram className="h-5 w-5" />,
      bio: "A passionate foodie and entrepreneur, Mat brings years of experience in building community-driven platforms. His vision for TryEverything stems from his belief that great food has the power to bring people together and create lasting connections."
    }
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">About Try Everything</h1>
      
      <div className="prose prose-lg">
        <p className="text-xl text-gray-600 mb-8">
          Try Everything is Canada's premier platform for discovering and experiencing the country's 
          vibrant food culture through unique, curated dining events.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">Our Story</h2>
        <p className="text-gray-600 mb-4">
          TryEverything started with two friends, Manan and Mat, who spent years dining out weekly, discovering new flavors, and bonding over great meals in Toronto, Ontario. When life took them to different cities, they wanted to keep that magic alive—not just for themselves, but for others too.
        </p>
        <p className="text-gray-600 mb-4">
          As passionate foodies, we believe in the power of food to connect people and create unforgettable experiences. That's why we make it easy for you to explore curated menus, meet like-minded diners, and savor the joy of shared meals.
        </p>
        <p className="text-gray-600 mb-8">
          Come hungry, connect, and let's TryEverything together.
        </p>
        <p className="text-gray-600 mb-8">
          Founded in Toronto and now operating in Vancouver, Calgary, and Montreal, Try Everything 
          helps thousands of Canadians discover new flavors, meet fellow food lovers, and create unforgettable dining memories.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">Our Mission</h2>
        <p className="text-gray-600 mb-8">
          We believe that the best way to experience a city's culture is through its food and people. 
          Our mission is to connect food enthusiasts with extraordinary dining experiences, creating 
          memorable moments around the table in Canada's most exciting restaurants.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">How It Works</h2>
        <p className="text-gray-600 mb-8">
          We partner with exceptional restaurants to create unique events where guests can experience 
          the entire menu in one sitting. Our carefully curated experiences bring together passionate 
          food lovers in an intimate setting, fostering connections and creating memorable dining moments.
        </p>

        <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">Our Values</h2>
        <ul className="list-disc pl-6 text-gray-600 mb-8">
          <li className="mb-2">
            <strong>Community First:</strong> We believe in the power of food to bring people together
          </li>
          <li className="mb-2">
            <strong>Quality Experience:</strong> Every event is carefully curated to ensure excellence
          </li>
          <li className="mb-2">
            <strong>Local Focus:</strong> We celebrate and support local restaurants and food culture
          </li>
          <li>
            <strong>Inclusivity:</strong> We create welcoming spaces for all food enthusiasts
          </li>
        </ul>
      </div>

      <div className="mt-16">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">Leadership Team</h2>
        <div className="grid md:grid-cols-2 gap-12 max-w-3xl mx-auto">
          {leadershipTeam.map((member, index) => (
            <div key={index} className="flex flex-col items-center">
              <div className="bg-white rounded-xl shadow-lg overflow-hidden w-full max-w-sm transform transition-transform duration-300 hover:scale-105">
                <div className="aspect-square w-48 h-48 mx-auto mt-8 relative">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
                <div className="p-6 text-center">
                  <h3 className="text-xl font-semibold text-gray-900 mb-1">{member.name}</h3>
                  <p className="text-gray-600 mb-4">{member.role}</p>
                  <p className="text-gray-700 mb-4">{member.bio}</p>
                  <a
                    href={member.socialLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center text-gray-600 hover:text-gray-900 transition-colors"
                  >
                    {member.socialIcon}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}