import React from 'react';
import { Users, Utensils, Star, BarChart2 } from 'lucide-react';

export function HowItWorks() {
  const steps = [
    { 
      icon: Users, 
      title: 'Join an Event', 
      desc: 'Browse and book your spot at upcoming events',
      image: 'https://images.unsplash.com/photo-1511795409834-ef04bbd61622?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80'
    },
    { 
      icon: Utensils, 
      title: 'Meet & Greet', 
      desc: 'Connect with new people and bond around food',
      image: 'https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80'
    },
    { 
      icon: Star, 
      title: 'Feast Together', 
      desc: 'Experience every dish on the menu',
      image: 'https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80'
    },
    { 
      icon: BarChart2, 
      title: 'Share & Earn', 
      desc: 'Share your unbiased review after the event and earn rewards for your next visit!',
      image: 'https://images.unsplash.com/photo-1552566626-52f8b828add9?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80'
    }
  ];

  return (
    <section className="py-16 sm:py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12 sm:mb-16">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-3 sm:mb-4">How It Works</h2>
          <p className="text-lg sm:text-xl text-gray-600">Your guide to extraordinary dining experiences</p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8">
          {steps.map(({ icon: Icon, title, desc, image }, i) => (
            <div key={i} className="group">
              <div className="relative h-48 sm:h-64 mb-4 sm:mb-6 overflow-hidden rounded-xl">
                <img 
                  src={image}
                  alt={title}
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                />
                <div className="absolute inset-0 bg-black/50 group-hover:bg-black/40 transition-colors">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <Icon className="h-10 w-10 sm:h-12 sm:w-12 text-white" />
                  </div>
                </div>
              </div>
              <h3 className="text-lg sm:text-xl font-bold text-gray-900 mb-2">{title}</h3>
              <p className="text-sm sm:text-base text-gray-600">{desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}