import React, { useEffect, useState } from 'react';
import { useSearchParams, Navigate, Link } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';
import { bookingService } from '../services/booking';
import { Button } from '../components/ui/Button';
import { eventService } from '../services/database';

export function CheckoutSuccessPage() {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    async function createBooking() {
      console.log('Starting checkout process with sessionId:', sessionId);
      
      if (!sessionId) {
        console.log('No sessionId found, aborting');
        return;
      }

      try {
        setLoading(true);
        console.log('Verifying payment with backend...');
        
        const response = await fetch('/.netlify/functions/verifyPayment', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sessionId })
        });

        const { success, data } = await response.json();
        console.log('Payment verification response:', { 
          success, 
          data,
          responseStatus: response.status 
        });

        if (success) {
          console.log('Payment verified successfully, creating booking...');
          
          // Add validation for required fields
          if (!data.userId) {
            console.error('Missing userId in payment data:', data);
            setError('Invalid user data. Please contact support.');
            return;
          }

          if (!data.eventId) {
            console.error('Missing eventId in payment data:', data);
            setError('Invalid event data. Please contact support.');
            return;
          }

          // Create booking first
          let booking;
          try {
            console.log('Creating booking with data:', {
              eventId: data.eventId,
              userId: data.userId,
              quantity: parseInt(data.quantity),
              totalAmount: data.amount / 100,
              stripeSessionId: sessionId
            });
            
            // Validate required fields before creating booking
            if (!data.userId || !data.eventId) {
              throw new Error('Missing required booking data');
            }
            
            booking = await bookingService.createBooking(
              data.eventId,
              data.userId,
              parseInt(data.quantity),
              data.amount / 100,
              sessionId
            );
            console.log('Booking created successfully:', booking);
          } catch (error) {
            console.error('Booking creation failed:', {
              error,
              errorMessage: error instanceof Error ? error.message : 'Unknown error',
              errorStack: error instanceof Error ? error.stack : undefined,
              data // Log the full data object for debugging
            });
            setError('Failed to create booking. Please contact support.');
            return;
          }

          // Try to update event count
          try {
            console.log('Updating event registration count...', {
              eventId: data.eventId,
              quantity: data.quantity
            });
            
            const updateResult = await eventService.updateRegistered(
              data.eventId, 
              parseInt(data.quantity)
            );
            
            console.log('Event registration update result:', updateResult);
          } catch (error) {
            console.error('Failed to update event count:', {
              error,
              errorMessage: error instanceof Error ? error.message : 'Unknown error',
              eventId: data.eventId,
              quantity: data.quantity
            });
            // Continue anyway since booking was created
          }

          // Redirect to confirmation page
          if (booking) {
            console.log('Redirecting to confirmation page...', {
              bookingId: booking.$id
            });
            window.location.replace(`/booking-confirmation?booking_id=${booking.$id}`);
          } else {
            console.error('No booking object after successful creation');
            setError('Booking creation failed. Please contact support.');
          }
        } else {
          console.error('Payment verification failed:', data);
          setError('Payment verification failed');
        }
      } catch (error) {
        console.error('Error in checkout process:', {
          error,
          errorMessage: error instanceof Error ? error.message : 'Unknown error',
          errorStack: error instanceof Error ? error.stack : undefined,
          sessionId
        });
        setError('Failed to process booking. Please contact support.');
      } finally {
        setLoading(false);
      }
    }

    createBooking();
  }, [sessionId]);

  if (!sessionId) {
    return <Navigate to="/events" replace />;
  }

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#29303D]"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-2xl mx-auto px-4 py-8 text-center">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          {error}
        </div>
        <Button onClick={() => window.location.href = '/events'}>
          Return to Events
        </Button>
      </div>
    );
  }

  return null; // The page will redirect before reaching this point
} 