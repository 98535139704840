export function formatDate(date: string | Date): string {
  const d = new Date(date);
  return d.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}

export function formatTime(time: string): string {
  // Handle different time formats
  let hours: string;
  let minutes: string;
  
  if (time.includes(':')) {
    [hours, minutes] = time.split(':');
  } else if (time.includes('PM') || time.includes('AM')) {
    // Handle cases where time already includes AM/PM
    const timeMatch = time.match(/(\d+):?(\d*)?\s*(AM|PM)/i);
    if (!timeMatch) return time;
    
    hours = timeMatch[1];
    minutes = timeMatch[2] || '00';
    const period = timeMatch[3].toUpperCase();
    
    if (period === 'PM' && hours !== '12') {
      hours = String(parseInt(hours) + 12);
    }
    if (period === 'AM' && hours === '12') {
      hours = '00';
    }
  } else {
    return time; // Return original if format not recognized
  }

  // Convert to numbers for manipulation
  let hour = parseInt(hours, 10);
  const min = minutes || '00';

  // Convert to 12-hour format
  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12;
  hour = hour || 12; // Convert 0 to 12

  // Format minutes to always have two digits
  const formattedMinutes = min.padStart(2, '0');

  return `${hour}:${formattedMinutes} ${ampm}`;
}

export function formatEventTime(time: string, timezone?: string): string {
  const formattedTime = formatTime(time);
  return timezone ? `${formattedTime} ${timezone}` : formattedTime;
} 