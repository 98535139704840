import React from 'react';
import { Card } from './Card';
import { ButtonLink } from './Button';
import { ChefHat } from 'lucide-react';

export function PartnerCard() {
  return (
    <Card>
      <div className="p-6">
        <div className="flex items-center gap-3 mb-4">
          <ChefHat className="w-6 h-6 text-gray-900" />
          <h3 className="font-semibold text-lg">Become a Partner</h3>
        </div>
        <p className="text-gray-600 text-sm mb-4">
          Want to host Try Everything events at your restaurant? Join our network of exceptional dining establishments.
        </p>
        <ButtonLink to="/partner" variant="secondary" className="w-full">
          Apply Now
        </ButtonLink>
      </div>
    </Card>
  );
} 