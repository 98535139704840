import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { account, databases } from '../../lib/appwrite';
import { appwriteConfig } from '../../config/appwrite';
import { ID, Query } from 'appwrite';

export function GoogleCallback() {
  const navigate = useNavigate();
  const { setUser, user } = useAuth();

  useEffect(() => {
    async function handleCallback() {
      try {
        console.log('Starting Google callback handling...');
        
        // Get the current session after OAuth redirect
        const session = await account.getSession('current');
        console.log('Session:', session);
        
        if (!session) {
          console.log('No session found, redirecting to login');
          navigate('/login', { replace: true });
          return;
        }

        // Get account details
        const accountDetails = await account.get();
        console.log('Account details:', accountDetails);
        
        // Check if user document exists
        const response = await databases.listDocuments(
          appwriteConfig.databaseId,
          appwriteConfig.usersCollectionId,
          [Query.equal('userId', accountDetails.$id)]
        );
        console.log('User document response:', response);

        let userDoc;
        if (response.documents.length === 0) {
          console.log('Creating new user document...');
          // Create new user document if it doesn't exist
          userDoc = await databases.createDocument(
            appwriteConfig.databaseId,
            appwriteConfig.usersCollectionId,
            ID.unique(),
            {
              userId: accountDetails.$id,
              email: accountDetails.email,
              name: accountDetails.name,
              role: 'user',
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              phone: '',
              city: '',
              avatar: '',
              restaurantId: null
            }
          );
          console.log('Created new user document:', userDoc);
        } else {
          console.log('Found existing user document');
          userDoc = response.documents[0];
        }

        // Set user in context
        const userData = {
          $id: userDoc.$id,
          userId: accountDetails.$id,
          email: accountDetails.email,
          name: accountDetails.name,
          role: userDoc.role || 'user',
          createdAt: userDoc.$createdAt,
          updatedAt: userDoc.$updatedAt,
          phone: userDoc.phone || '',
          city: userDoc.city || '',
          avatar: userDoc.avatar || '',
          restaurantId: userDoc.restaurantId || null
        };
        console.log('Setting user data:', userData);
        setUser(userData);
        
      } catch (error: any) {
        console.error('Google callback error:', error);
        // Add specific error handling
        if (error?.code === 401) {
          console.log('Unauthorized, redirecting to login');
          navigate('/login', { replace: true });
        } else {
          console.log('Other error, redirecting to login');
          navigate('/login', { replace: true });
        }
      }
    }

    handleCallback();
  }, [navigate, setUser]);

  // Watch for user context to be set before navigating
  useEffect(() => {
    if (user) {
      console.log('User context set, navigating to settings...');
      navigate('/settings', { replace: true });
    }
  }, [user, navigate]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      <p className="mt-4 text-gray-600">Completing sign in...</p>
    </div>
  );
} 