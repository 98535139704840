import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HeroSection } from '../components/home/HeroSection';
import { FeaturedEvents } from '../components/home/FeaturedEvents';
import { HowItWorks } from '../components/home/HowItWorks';
import { CTASection } from '../components/home/CTASection';

export function HomePage() {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToHero) {
      const heroContent = document.querySelector('#hero-content');
      if (heroContent) {
        heroContent.scrollIntoView({ behavior: 'smooth' });
      }
      // Clean up the state
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  return (
    <div>
      <HeroSection />
      <FeaturedEvents />
      <HowItWorks />
      <CTASection />
    </div>
  );
}