import { useState, useEffect } from 'react';
import { databases, DATABASE_ID, COLLECTIONS } from '../lib/appwrite';
import { Query } from 'appwrite';
import type { Event } from '../types/database';

export function useRelatedEvents(city: string | undefined, currentEventId: string | undefined) {
  const [relatedEvents, setRelatedEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchRelatedEvents() {
      if (!city || !currentEventId) {
        setRelatedEvents([]);
        setLoading(false);
        return;
      }

      try {
        const response = await databases.listDocuments(
          DATABASE_ID,
          COLLECTIONS.EVENTS,
          [
            Query.equal('city', city),
            Query.equal('status', 'published'),
            Query.notEqual('$id', currentEventId), // Exclude current event
            Query.limit(3) // Limit to 3 related events
          ]
        );

        setRelatedEvents(response.documents as Event[]);
      } catch (err) {
        console.error('Failed to fetch related events:', err);
        setError(err instanceof Error ? err.message : 'Failed to load related events');
      } finally {
        setLoading(false);
      }
    }

    fetchRelatedEvents();
  }, [city, currentEventId]);

  return { relatedEvents, loading, error };
} 