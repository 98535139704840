import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, MapPin, Users } from 'lucide-react';
import { format } from 'date-fns';
import { databases } from '../../lib/appwrite';
import { appwriteConfig } from '../../config/appwrite';
import { Query } from 'appwrite';
import type { Event } from '../../types/database';

export function FeaturedEvents() {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchEvents() {
      try {
        const response = await databases.listDocuments(
          appwriteConfig.databaseId,
          appwriteConfig.eventsCollectionId,
          [
            Query.greaterThan('date', new Date().toISOString()),
            Query.equal('status', 'published'),
            Query.limit(12)
          ]
        );

        setEvents(response.documents as Event[]);
      } catch (error) {
        console.error('Failed to fetch events:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchEvents();
  }, []);

  if (loading || events.length === 0) {
    return null;
  }

  // Group events by city
  const eventsByCity = events.reduce((acc, event) => {
    if (!acc[event.city]) {
      acc[event.city] = [];
    }
    acc[event.city].push(event);
    return acc;
  }, {} as Record<string, Event[]>);

  console.log('Events grouped by city:', eventsByCity);

  // Get one event from each city
  const featuredEvents = Object.entries(eventsByCity).map(([city, cityEvents]) => {
    console.log(`Processing city ${city} with ${cityEvents.length} events`);
    return cityEvents.reduce((earliest, current) => {
      return new Date(current.date) < new Date(earliest.date) ? current : earliest;
    }, cityEvents[0]);
  });

  console.log('Final featured events:', featuredEvents);

  if (featuredEvents.length === 0) {
    return null;
  }

  return (
    <section className="py-16 sm:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12 sm:mb-16">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-3 sm:mb-4">Our Next Experiences</h2>
          <p className="text-lg sm:text-xl text-gray-600">
            Reserve your spot at our upcoming dining events
            <span className="block text-xs sm:text-sm text-gray-500 mt-2">
              Waitlist members get early access 24 hours before public release
            </span>
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          {featuredEvents.map((event) => (
            <Link 
              key={event.$id} 
              to={`/events/${event.$id}`} 
              className="group block relative h-[360px] sm:h-[420px] overflow-hidden rounded-xl shadow-lg"
            >
              <img 
                src={event.imageUrl}
                alt={event.title}
                className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-black/20" />
              
              {event.registered >= event.capacity && (
                <div className="absolute top-3 sm:top-4 right-3 sm:right-4 bg-red-500 text-white px-2 sm:px-3 py-1 rounded-full text-xs sm:text-sm font-medium shadow-md z-10">
                  Sold Out
                </div>
              )}
              
              <div className="absolute inset-0 flex flex-col backdrop-blur-[2px]">
                <div className="p-4 sm:p-6">
                  <h3 className="text-lg sm:text-xl font-bold text-white border-b border-white/20 pb-2">
                    {event.city}
                  </h3>
                </div>

                <div className="mt-auto p-4 sm:p-6 text-white">
                  <h4 className="text-base sm:text-lg font-bold mb-2">{event.title}</h4>
                  <div className="space-y-2 text-xs sm:text-sm">
                    <div className="flex items-center">
                      <Calendar className="h-3 w-3 sm:h-4 sm:w-4 mr-2" />
                      <span>{format(new Date(event.date), 'MMMM d, yyyy')} at {event.time}</span>
                    </div>
                    <div className="flex items-center">
                      <MapPin className="h-3 w-3 sm:h-4 sm:w-4 mr-2" />
                      <span>{event.city}</span>
                    </div>
                    <div className="flex items-center">
                      <Users className="h-3 w-3 sm:h-4 sm:w-4 mr-2" />
                      <span>
                        {event.registered >= event.capacity ? (
                          <span className="text-red-400 font-medium">Sold Out</span>
                        ) : (
                          `${event.capacity - event.registered} spots left`
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="mt-3 sm:mt-4 flex items-center justify-between">
                    <span className="text-xl sm:text-2xl font-bold">${event.price}</span>
                    <span className="text-xs sm:text-sm">Book Now →</span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}