import React from 'react';
import { Star, MapPin, DollarSign } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Card } from '../ui/Card';
import { ButtonLink } from '../ui/Button';
import type { Restaurant } from '../../types/database';

interface RestaurantGridProps {
  restaurants: Restaurant[];
}

export function RestaurantGrid({ restaurants }: RestaurantGridProps) {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
      {restaurants.map((restaurant) => (
        <Card key={restaurant.$id} className="overflow-hidden">
          <img
            src={restaurant.imageUrl}
            alt={restaurant.name}
            className="w-full h-48 object-cover"
          />
          <div className="p-6 space-y-4">
            <h3 className="text-xl font-semibold text-gray-900">{restaurant.name}</h3>
            <p className="text-gray-600 line-clamp-2">{restaurant.description}</p>
            
            <div className="flex items-center justify-between text-sm text-gray-600">
              <div className="flex items-center">
                <MapPin className="h-4 w-4 mr-1" />
                <span>{restaurant.city}</span>
              </div>
              <div className="flex items-center">
                <Star className="h-4 w-4 mr-1 text-yellow-400" />
                <span>{restaurant.rating}</span>
              </div>
              <div className="flex items-center">
                <DollarSign className="h-4 w-4 mr-1" />
                <span>{restaurant.priceRange}</span>
              </div>
            </div>

            <ButtonLink
              to={`/restaurants/${restaurant.restaurantId}`}
              variant="secondary"
              className="w-full text-center"
            >
              View Details
            </ButtonLink>
          </div>
        </Card>
      ))}
    </div>
  );
}