import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Card } from '../../components/ui/Card';
import { databases } from '../../lib/appwrite';
import { appwriteConfig } from '../../config/appwrite';
import { Query } from 'appwrite';
import { format } from 'date-fns';

interface Booking {
  $id: string;
  userId: string;
  eventId: string;
  event: {
    title: string;
    date: string;
    time: string;
    imageUrl: string;
    restaurantName: string;
  };
  quantity: number;
  status: string;
  createdAt: string;
}

export function BookingsPage() {
  const { user } = useAuth();
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchBookings() {
      if (!user) return;

      try {
        setLoading(true);
        const response = await databases.listDocuments(
          appwriteConfig.databaseId,
          appwriteConfig.bookingsCollectionId,
          [
            Query.equal('userId', user.userId),
            Query.orderDesc('$createdAt')
          ]
        );

        const bookingsWithEvents = await Promise.all(
          response.documents.map(async (booking) => {
            try {
              const eventResponse = await databases.getDocument(
                appwriteConfig.databaseId,
                appwriteConfig.eventsCollectionId,
                booking.eventId
              );

              return {
                $id: booking.$id,
                userId: booking.userId,
                eventId: booking.eventId,
                quantity: booking.quantity,
                status: booking.status,
                createdAt: booking.$createdAt,
                event: {
                  title: eventResponse.title,
                  date: eventResponse.date,
                  time: eventResponse.time,
                  imageUrl: eventResponse.imageUrl,
                  restaurantName: eventResponse.restaurantName
                }
              } as Booking;
            } catch (error) {
              console.error('Failed to fetch event details:', error);
              return {
                $id: booking.$id,
                userId: booking.userId,
                eventId: booking.eventId,
                quantity: booking.quantity,
                status: booking.status,
                createdAt: booking.$createdAt,
                event: {
                  title: 'Event details not available',
                  date: '',
                  time: '',
                  imageUrl: '',
                  restaurantName: ''
                }
              } as Booking;
            }
          })
        );

        setBookings(bookingsWithEvents);
      } catch (error) {
        console.error('Failed to fetch bookings:', error);
        setError('Failed to load bookings');
      } finally {
        setLoading(false);
      }
    }

    fetchBookings();
  }, [user]);

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="animate-pulse">
          <div className="h-8 w-48 bg-gray-200 rounded mb-8"></div>
          <div className="space-y-6">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-32 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 text-red-700 p-4 rounded-md">
          {error}
        </div>
      </div>
    );
  }

  if (!bookings.length) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">My Bookings</h1>
        <Card className="p-6 text-center text-gray-500">
          You haven't made any bookings yet.
        </Card>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">My Bookings</h1>
      <div className="space-y-6">
        {bookings.map((booking) => (
          <Card key={booking.$id} className="p-6">
            <div className="flex items-start space-x-6">
              <img
                src={booking.event.imageUrl || 'https://via.placeholder.com/150'}
                alt={booking.event.title}
                className="w-32 h-32 object-cover rounded-lg"
              />
              <div className="flex-1">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {booking.event.title}
                </h3>
                <div className="text-gray-600 space-y-1">
                  <p>Restaurant: {booking.event.restaurantName}</p>
                  <p>Date: {format(new Date(booking.event.date), 'MMMM d, yyyy')}</p>
                  <p>Time: {booking.event.time}</p>
                  <p>Tickets: {booking.quantity}</p>
                  <p className="capitalize">Status: {booking.status}</p>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}