import { useState, useEffect } from 'react';
import { eventService } from '../services/database';
import type { Event } from '../types/database';

export function useRestaurantEvents(restaurantId: string | undefined) {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let mounted = true;

    async function fetchEvents() {
      console.log('Fetching events for restaurant:', restaurantId);
      
      if (!restaurantId) {
        console.warn('No restaurantId provided to useRestaurantEvents');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);
        const data = await eventService.listByRestaurant(restaurantId);
        
        if (mounted) {
          setEvents(data);
        }
      } catch (err) {
        console.error('Failed to fetch restaurant events:', err);
        if (mounted) {
          setError(err instanceof Error ? err.message : 'Failed to load events');
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    fetchEvents();

    return () => {
      mounted = false;
    };
  }, [restaurantId]);

  return { events, loading, error };
} 