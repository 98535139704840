import React, { useState } from 'react';
import { RestaurantFilters } from '../components/restaurants/RestaurantFilters';
import { RestaurantGrid } from '../components/restaurants/RestaurantGrid';
import { useRestaurants } from '../hooks/useRestaurants';
import { Button } from '../components/ui/Button';

export function RestaurantsPage() {
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCuisine, setSelectedCuisine] = useState('');
  const { restaurants, loading, error } = useRestaurants(selectedCity, selectedCuisine);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 text-center">
        <div className="bg-red-50 text-red-700 p-4 rounded-md">
          {error}
        </div>
        <Button 
          onClick={() => window.location.reload()} 
          className="mt-4"
        >
          Try Again
        </Button>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-900">
          Partner Restaurants Across Canada
        </h1>
        <p className="text-xl text-gray-600 mt-2">
          Discover exceptional restaurants that host Try Everything events
        </p>
      </div>

      <div className="flex flex-col md:flex-row md:items-center gap-4">
        <div className="flex-grow">
          <RestaurantFilters
            selectedCity={selectedCity}
            onCityChange={setSelectedCity}
            selectedCuisine={selectedCuisine}
            onCuisineChange={setSelectedCuisine}
          />
        </div>
      </div>

      <RestaurantGrid restaurants={restaurants} />
    </div>
  );
}