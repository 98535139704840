import { loadStripe } from '@stripe/stripe-js';

let stripePromise: Promise<any> | null = null;

export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

export const stripeService = {
  async createCheckoutSession({
    eventId,
    userId,
    quantity,
    unitAmount,
    title,
    imageUrl,
    date,
    time,
    restaurant
  }: {
    eventId: string;
    userId: string;
    quantity: number;
    unitAmount: number;
    title: string;
    imageUrl?: string;
    date: string;
    time: string;
    restaurant?: {
      name?: string;
      address?: string;
    };
  }) {
    console.log('Creating checkout session with:', {
      eventId,
      userId,
      quantity,
      unitAmount,
      title,
      date,
      time,
      restaurant
    });

    const response = await fetch('/.netlify/functions/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventId,
        userId,
        quantity,
        unitAmount,
        metadata: {
          event_title: title,
          event_date: date,
          event_time: time,
          restaurant_name: restaurant?.name || 'Restaurant details loading...',
          restaurant_address: restaurant?.address || 'Address loading...'
        }
      }),
    });

    const data = await response.json();
    console.log('Checkout session response:', data);

    if (!response.ok) {
      console.error('Checkout session creation failed:', data);
      throw new Error(data.error || 'Failed to create checkout session');
    }
    return data;
  }
}; 