import React, { createContext, useContext, useState } from 'react';
import { useAuth as useAuthHook } from '../hooks/useAuth';
import { account, databases, ID } from '../lib/appwrite';
import { appwriteConfig } from '../config/appwrite';
import type { Models } from 'appwrite';
import { Query } from 'appwrite';

interface User {
  $id: string;
  userId: string;
  email: string;
  name: string;
  role: string;
  createdAt: string;
  updatedAt: string;
  phone?: string;
  city?: string;
  avatar?: string;
  restaurantId?: string | null;
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  error: string | null;
  setUser: (user: User | null) => void;
  signInWithGoogle: () => Promise<void>;
  signInWithEmail: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchUserDocument = async (userId: string): Promise<User | null> => {
    try {
      const response = await databases.listDocuments(
        appwriteConfig.databaseId,
        appwriteConfig.usersCollectionId,
        [Query.equal('userId', userId)]
      );

      if (response.documents.length > 0) {
        const doc = response.documents[0];
        return {
          $id: doc.$id,
          userId: doc.userId,
          email: doc.email,
          name: doc.name,
          role: doc.role,
          createdAt: doc.createdAt,
          updatedAt: doc.updatedAt,
          phone: doc.phone,
          city: doc.city,
          avatar: doc.avatar,
          restaurantId: doc.restaurantId
        };
      }
      return null;
    } catch (error) {
      console.error('Failed to fetch user document:', error);
      return null;
    }
  };

  const clearExistingSession = async () => {
    try {
      const currentSession = await account.getSession('current');
      if (currentSession) {
        await account.deleteSession('current');
      }
    } catch (error) {
      // Ignore session check errors
      console.log('No active session found');
    }
  };

  const signInWithEmail = async (email: string, password: string) => {
    try {
      setLoading(true);
      // Clear any existing session first
      await clearExistingSession();
      
      // Create new email session
      await account.createEmailSession(email, password);
      const accountDetails = await account.get();
      const userDoc = await fetchUserDocument(accountDetails.$id);
      if (userDoc) {
        setUser(userDoc);
      }
    } catch (error) {
      console.error('Email sign in error:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    try {
      setLoading(true);
      // Clear any existing session first
      await clearExistingSession();
      
      // Create OAuth2 session with the correct redirect URLs
      await account.createOAuth2Session(
        'google',
        `${window.location.origin}/auth/callback/google`,  // Success URL
        `${window.location.origin}/login`,                 // Failure URL
        []  // No additional scopes needed
      );
    } catch (error: any) {
      console.error('Google sign in error:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    try {
      await account.deleteSession('current');
      setUser(null);
    } catch (error) {
      console.error('Sign out error:', error);
      throw error;
    }
  };

  const value = {
    user,
    loading,
    error,
    setUser,
    signInWithEmail,
    signInWithGoogle,
    signOut
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}