import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../ui/Card';
import { Calendar, MapPin, Users } from 'lucide-react';
import { formatDate, formatEventTime } from '../../utils/date';
import type { Event } from '../../types/database';
import { Button } from '../ui/Button';

interface EventCardProps {
  event: Event;
  compact?: boolean;
}

export function EventCard({ event, compact = false }: EventCardProps) {
  return (
    <Link to={`/events/${event.$id}`}>
      <Card className="h-full overflow-hidden hover:shadow-lg transition-shadow flex flex-col bg-[#29303D]/[0.02]">
        <div className="relative h-48 md:h-56 lg:h-48 xl:h-56">
          <img 
            src={event.imageUrl} 
            alt={event.title}
            className="w-full h-full object-cover"
          />
          {/* Urgency badge */}
          {event.capacity - event.registered <= 5 && (
            <div className="absolute top-4 right-4 bg-[#29303D] text-white px-3 py-1 rounded-full text-sm font-medium shadow-md">
              Only {event.capacity - event.registered} spots left!
            </div>
          )}
        </div>
        <div className="p-6 flex-grow flex flex-col">
          <div className="mb-4">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-xl font-bold text-[#29303D] line-clamp-2">
                {event.title}
              </h3>
              <span className="text-2xl font-bold text-[#29303D] ml-4 whitespace-nowrap">
                ${event.price}
              </span>
            </div>
            {!compact && (
              <p className="text-[#29303D]/70 text-sm line-clamp-2 mb-4">
                {event.description}
              </p>
            )}
          </div>
          <div className="flex-grow">
            <div className="space-y-2 text-sm text-[#29303D]/70">
              <div className="flex items-center">
                <Calendar className="h-4 w-4 mr-2 flex-shrink-0 text-[#29303D]" />
                <span>{formatDate(event.date)} at {formatEventTime(event.time)}</span>
              </div>
              <div className="flex items-center">
                <MapPin className="h-4 w-4 mr-2 flex-shrink-0 text-[#29303D]" />
                <span className="line-clamp-1">{event.restaurant?.name}</span>
              </div>
              <div className="flex items-center">
                <Users className="h-4 w-4 mr-2 flex-shrink-0 text-[#29303D]" />
                <span className={`${event.capacity - event.registered <= 5 ? 'text-[#29303D] font-medium' : ''}`}>
                  {event.capacity - event.registered} spots left
                </span>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Button 
              className="w-full bg-[#29303D] hover:bg-[#29303D]/90 text-white shadow-md font-semibold text-lg py-3"
            >
              Book Now →
            </Button>
          </div>
        </div>
      </Card>
    </Link>
  );
}