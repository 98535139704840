import React from 'react';

export function CookiesPage() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">Cookie Policy for TryEverything</h1>
      
      <div className="prose prose-lg">
        <p className="text-gray-600 mb-8">
          Effective Date: December 11th, 2024
        </p>

        <p className="text-gray-600 mb-8">
          This Cookie Policy explains how TryEverything ("we", "us", or "our") uses cookies and similar technologies to recognize you when you visit our website. It explains what these technologies are and why we use them, as well as your rights to control our use of them.
        </p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">What Are Cookies?</h2>
          <p className="text-gray-600 mb-4">
            Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.
          </p>
          <p className="text-gray-600">
            Cookies set by the website owner (in this case, TryEverything) are called "first-party cookies". Cookies set by parties other than the website owner are called "third-party cookies". Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics).
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Types of Cookies We Use</h2>
          
          <h3 className="text-xl font-semibold text-gray-900 mb-3">Essential Cookies</h3>
          <p className="text-gray-600 mb-4">
            These cookies are strictly necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-6">
            <li className="mb-2">Setting your privacy preferences</li>
            <li className="mb-2">Logging in or filling in forms</li>
            <li className="mb-2">Processing Event bookings and payments</li>
            <li className="mb-2">Basic website functionality</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Performance and Analytics Cookies</h3>
          <p className="text-gray-600 mb-4">
            These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. Information these cookies collect includes:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-6">
            <li className="mb-2">Pages visited and navigation patterns</li>
            <li className="mb-2">Length of visits and bounce rates</li>
            <li className="mb-2">Traffic sources and user demographics</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Functional Cookies</h3>
          <p className="text-gray-600 mb-4">
            These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third-party providers whose services we have added to our pages. They help with:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-6">
            <li className="mb-2">Remembering your preferences and settings</li>
            <li className="mb-2">Saving your login information</li>
            <li className="mb-2">Customizing your experience based on location</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Marketing and Targeting Cookies</h3>
          <p className="text-gray-600 mb-4">
            These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They help with:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Displaying relevant promotional content</li>
            <li className="mb-2">Measuring marketing campaign effectiveness</li>
            <li className="mb-2">Understanding user preferences and behavior</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">How Can You Control Cookies?</h2>
          <p className="text-gray-600 mb-4">
            You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences in the following ways:
          </p>
          
          <h3 className="text-xl font-semibold text-gray-900 mb-3">Browser Settings</h3>
          <p className="text-gray-600 mb-4">
            You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information.
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Cookie Preference Center</h3>
          <p className="text-gray-600 mb-4">
            When you first visit our website, you will be presented with a cookie banner that allows you to accept or reject different types of cookies. You can change your preferences at any time by clicking on the "Cookie Settings" link in the footer of our website.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Do Not Track</h2>
          <p className="text-gray-600 mb-4">
            Some browsers have incorporated "Do Not Track" (DNT) features that can send a signal to the websites you visit indicating you do not wish to be tracked. Because there is not yet a common understanding of how to interpret the DNT signal, our website does not currently respond to browser DNT signals. Instead, you can use the range of other tools we provide to control data collection and use.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Updates to This Cookie Policy</h2>
          <p className="text-gray-600 mb-4">
            We may update this Cookie Policy from time to time in order to reflect changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
          </p>
          <p className="text-gray-600">
            The date at the top of this Cookie Policy indicates when it was last updated.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Contact Us</h2>
          <p className="text-gray-600 mb-4">
            If you have any questions about our use of cookies or other technologies, please email us at tryeverything.mgmt@gmail.com.
          </p>
        </section>

        <section className="mb-8">
          <p className="text-gray-600 italic">
            By using TryEverything's website, you consent to the use of cookies as described in this Cookie Policy. If you do not agree to our use of cookies, you should set your browser settings accordingly or not use our website.
          </p>
        </section>
      </div>
    </div>
  );
}