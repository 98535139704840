import { databases, DATABASE_ID, COLLECTIONS, ID, Query } from '../lib/appwrite';
import type { Restaurant, Event, Booking } from '../types/database';

// Restaurant Services
export const restaurantService = {
  // List all restaurants with optional filters
  list: async (filters?: { city?: string; cuisine?: string }) => {
    const queries = [Query.equal('status', ['approved'])];
    
    if (filters?.city) {
      queries.push(Query.equal('city', filters.city));
    }
    
    if (filters?.cuisine) {
      queries.push(Query.equal('cuisine', filters.cuisine));
    }

    queries.push(Query.orderDesc('rating'));

    console.log('Fetching restaurants with:', {
      databaseId: DATABASE_ID,
      collectionId: COLLECTIONS.RESTAURANTS,
      queries
    });

    const response = await databases.listDocuments(
      DATABASE_ID,
      COLLECTIONS.RESTAURANTS,
      queries
    );

    return response.documents as Restaurant[];
  },

  // Get a single restaurant by restaurantId
  get: async (restaurantId: string) => {
    if (!restaurantId) {
      console.warn('No restaurant ID provided');
      return null;
    }

    try {
      const response = await databases.listDocuments(
        DATABASE_ID,
        COLLECTIONS.RESTAURANTS,
        [Query.equal('restaurantId', restaurantId)]
      );

      if (response.documents.length === 0) {
        console.warn('No restaurant found with restaurantId:', restaurantId);
        return null;
      }

      return response.documents[0] as Restaurant;
    } catch (error) {
      console.error('Failed to fetch restaurant:', {
        error,
        restaurantId,
        collectionId: COLLECTIONS.RESTAURANTS
      });
      throw error;
    }
  }
};

// Event Services
export const eventService = {
  // List all events with optional filters
  list: async () => {
    try {
      console.log('Fetching events from collection:', COLLECTIONS.EVENTS);
      
      const response = await databases.listDocuments(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        [
          Query.equal('status', 'published'),
          Query.greaterThanEqual('date', new Date().toISOString().split('T')[0]),
          Query.orderAsc('date'),
          Query.limit(100)
        ]
      );

      console.log('Events response:', response);
      return response.documents as Event[];
    } catch (error) {
      console.error('Failed to fetch events:', error);
      throw error; // Let the hook handle the error
    }
  },

  // Get a single event
  get: async (id: string) => {
    try {
      const event = await databases.getDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        id
      );

      console.log('Event fetch:', {
        event,
        restaurantId: event.restaurantId,
        eventId: id
      });

      // Get restaurant using restaurantId field query
      if (event.restaurantId) {
        const restaurantResponse = await databases.listDocuments(
          DATABASE_ID,
          COLLECTIONS.RESTAURANTS,
          [Query.equal('restaurantId', event.restaurantId)]
        );
        
        if (restaurantResponse.documents.length > 0) {
          console.log('Found associated restaurant:', restaurantResponse.documents[0]);
        }
      }

      return event as Event;
    } catch (error) {
      console.error('Event fetch error:', error);
      return null; // Return null instead of throwing
    }
  },

  listByRestaurant: async (restaurantId: string) => {
    console.log('listByRestaurant called with:', restaurantId);
    
    try {
      const queries = [
        Query.equal('restaurantId', restaurantId), // Use restaurantId directly
        Query.equal('status', 'published'),
        Query.greaterThanEqual('date', new Date().toISOString().split('T')[0]),
        Query.orderAsc('date')
      ];

      console.log('Fetching events with queries:', {
        restaurantId,
        queries: queries.map(q => q.toString())
      });

      const response = await databases.listDocuments(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        queries
      );

      console.log('Restaurant events response:', {
        total: response.total,
        documents: response.documents
      });

      return response.documents as Event[];
    } catch (error) {
      console.error('Failed to fetch restaurant events:', {
        error,
        restaurantId,
        collectionId: COLLECTIONS.EVENTS
      });
      throw error;
    }
  },

  updateRegistered: async (eventId: string, additionalRegistrations: number) => {
    try {
      // First get the current event
      const event = await databases.getDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        eventId
      ) as Event;

      // Update the registered count
      const response = await databases.updateDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        eventId,
        {
          registered: (event.registered || 0) + additionalRegistrations,
          updatedAt: new Date().toISOString()
        }
      );

      return response as Event;
    } catch (error) {
      console.error('Failed to update event registration:', error);
      // Don't throw the error, just log it
      return null;
    }
  },

  async updateRegistration(eventId: string, quantity: number) {
    try {
      // First get the current event to check capacity
      const event = await databases.getDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        eventId
      );

      const newRegisteredCount = (event.registered || 0) + quantity;

      // Check if there's enough capacity
      if (newRegisteredCount > event.capacity) {
        throw new Error('Not enough tickets available');
      }

      // Update the registration count
      const updatedEvent = await databases.updateDocument(
        DATABASE_ID,
        COLLECTIONS.EVENTS,
        eventId,
        {
          registered: newRegisteredCount,
          status: newRegisteredCount >= event.capacity ? 'sold_out' : 'published',
          updatedAt: new Date().toISOString()
        }
      );

      return updatedEvent;
    } catch (error) {
      console.error('Failed to update event registration:', error);
      throw error;
    }
  }
};

// Booking Services
export const bookingService = {
  // Create a booking
  create: async (data: Omit<Booking, 'id' | '$id' | '$createdAt' | '$updatedAt'>) => {
    try {
      console.log('Creating booking with data:', data);
      const response = await databases.createDocument(
        DATABASE_ID,
        COLLECTIONS.BOOKINGS,
        ID.unique(),
        data
      );
      console.log('Booking created:', response);
      return response as Booking;
    } catch (error) {
      console.error('Failed to create booking:', error);
      throw error;
    }
  },

  // List user's bookings
  listUserBookings: async (userId: string) => {
    const response = await databases.listDocuments(
      DATABASE_ID,
      COLLECTIONS.BOOKINGS,
      [
        Query.equal('userId', userId),
        Query.orderDesc('$createdAt')
      ]
    );
    return response.documents as Booking[];
  },

  // Update booking status
  updateStatus: async (id: string, status: Booking['status']) => {
    const response = await databases.updateDocument(
      DATABASE_ID,
      COLLECTIONS.BOOKINGS,
      id,
      { status }
    );
    return response as Booking;
  },

  // Get a single booking
  get: async (bookingId: string) => {
    try {
      const response = await databases.getDocument(
        DATABASE_ID,
        COLLECTIONS.BOOKINGS,
        bookingId
      );
      return response as Booking;
    } catch (error) {
      console.error('Failed to fetch booking:', error);
      throw error;
    }
  },
}; 