import { useState, useEffect } from 'react';
import { databases, DATABASE_ID, COLLECTIONS, Query } from '../lib/appwrite';
import type { Booking } from '../types/database';

export function useUserEventBooking(userId: string | undefined, eventId: string | undefined) {
  const [booking, setBooking] = useState<Booking | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchBooking() {
      if (!userId || !eventId) {
        setLoading(false);
        return;
      }

      try {
        const response = await databases.listDocuments(
          DATABASE_ID,
          COLLECTIONS.BOOKINGS,
          [
            Query.equal('userId', userId),
            Query.equal('eventId', eventId)
          ]
        );

        if (response.documents.length > 0) {
          setBooking(response.documents[0] as Booking);
        } else {
          setBooking(null);
        }
      } catch (err) {
        console.error('Failed to fetch user booking:', err);
        setError('Failed to check booking status');
      } finally {
        setLoading(false);
      }
    }

    fetchBooking();
  }, [userId, eventId]);

  return { booking, loading, error };
} 