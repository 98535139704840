import React from 'react';

export function TermsPage() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">Terms of Service for TryEverything</h1>
      
      <div className="prose prose-lg">
        <p className="text-gray-600 mb-8">
          Effective Date: December 11th, 2024
        </p>

        <p className="text-gray-600 mb-8">
          Welcome to TryEverything! This company is registered in Ontario, Canada. By using our services, you agree to the following Terms of Service. Please read them carefully before proceeding.
        </p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Introduction and Scope of Services</h2>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="mb-2">TryEverything provides culinary experiences that allow guests to explore curated menus at designated restaurants. Events are organized on specific dates, and participants pay in advance for tickets that grant access to these dining experiences.</li>
            <li className="mb-2">By purchasing tickets or attending an Event, you agree to these Terms of Service and the responsibilities outlined herein. If you do not accept these terms, you may not use our services.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Event Registrations and Reservations</h2>
          <p className="text-gray-600 mb-4">
            Our Event registration and reservation system is designed to ensure a smooth and organized experience for all participants. The following terms govern the registration process:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">2.1 Eligibility</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Participants must be at least 18 years of age to register for Events.</li>
            <li className="mb-2">Valid identification may be required at the Event for age verification, especially when alcohol service is available at the venue.</li>
            <li className="mb-2">By registering, you confirm that you have the legal capacity to enter into binding contracts.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">2.2 Registration Process</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">All registrations must be completed through our official website or authorized platforms.</li>
            <li className="mb-2">You must provide accurate and complete information during registration.</li>
            <li className="mb-2">False or misleading information may result in the cancellation of your registration without refund.</li>
            <li className="mb-2">You will receive a confirmation email once your registration is successfully processed.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">2.3 Registration Periods</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Registrations for Events open and close as specified on the event pages. Participants are responsible for reviewing and adhering to the registration deadlines outlined for each Event.</li>
            <li className="mb-2">Only registrations completed through TryEverything's official platform are valid. Any reservations made through unauthorized channels will be refused and are non-refundable.</li>
            <li className="mb-2">Early bird registrations, when available, are subject to limited availability and special pricing conditions.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">2.4 Waitlist Policy</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">When an Event is fully booked, you may join the waitlist.</li>
            <li className="mb-2">Waitlist positions are assigned on a first-come, first-served basis.</li>
            <li className="mb-2">If a spot becomes available, waitlisted participants will be notified via email and have 24 hours to complete their registration.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">2.5 Group Bookings</h3>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="mb-2">Group bookings of 4 or more participants may be eligible for special arrangements.</li>
            <li className="mb-2">Group bookings must be made at least 2 weeks in advance of the Event date.</li>
            <li className="mb-2">Special terms or pricing for group bookings are subject to availability and must be confirmed in writing by TryEverything.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Tickets and Payment</h2>
          <p className="text-gray-600 mb-4">
            Our ticketing system is designed to provide a secure and transparent payment process. The following terms apply to all ticket purchases:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">3.1 Ticket Pricing</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">All Event tickets must be purchased in advance. The ticket price covers the curated menu provided during the Event but excludes beverages, including alcohol.</li>
            <li className="mb-2">Prices are listed in Canadian Dollars (CAD) and include applicable taxes.</li>
            <li className="mb-2">Special pricing or promotional offers may be available and are subject to specific terms and conditions.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">3.2 Payment Methods</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">We accept major credit cards, debit cards, and other specified payment methods as indicated during checkout.</li>
            <li className="mb-2">All payments are processed through secure, PCI-compliant payment processors.</li>
            <li className="mb-2">Your payment information is never stored on our servers.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">3.3 Additional Charges</h3>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="mb-2">Alcohol will not be served by TryEverything, and any alcoholic beverages must be purchased directly from the hosting restaurant.</li>
            <li className="mb-2">Additional menu items, beverages, or special requests beyond the curated menu must be paid directly to the restaurant.</li>
            <li className="mb-2">Gratuity for additional purchases is not included in the ticket price and is at the discretion of the participant.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Cancellation and Refund Policy</h2>
          <p className="text-gray-600 mb-4">
            We understand that circumstances may change, but due to the nature of our Events and commitments to our restaurant partners, we maintain a strict cancellation policy:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">4.1 Non-Refundable Tickets</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Tickets are non-refundable unless the Event is canceled or rescheduled by TryEverything.</li>
            <li className="mb-2">Cancellations initiated by the Participant are not eligible for refunds.</li>
            <li className="mb-2">In case of inability to attend, you may request to transfer your ticket to another person, subject to approval (see Non-Transferability of Tickets section).</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">4.2 Event Cancellation by TryEverything</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">If we cancel an Event, you will receive a full refund of your ticket price.</li>
            <li className="mb-2">Refunds will be processed within 5-10 business days using the original payment method.</li>
            <li className="mb-2">We are not responsible for any incidental expenses you may have incurred.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">4.3 Event Rescheduling</h3>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="mb-2">If an Event is rescheduled, your ticket will automatically be valid for the new date.</li>
            <li className="mb-2">If you cannot attend the rescheduled date, you may request a refund within 48 hours of the rescheduling announcement.</li>
            <li className="mb-2">After 48 hours, the standard non-refundable ticket policy applies.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Event Details and Responsibilities</h2>
          <p className="text-gray-600 mb-4">
            To ensure the best possible experience for all participants, we have established the following guidelines and responsibilities:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">5.1 Event Format</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Events are designed for groups of approximately 8+ guests and last approximately 2 hours.</li>
            <li className="mb-2">The exact location of the restaurant will be communicated to registered Participants at minimum 24 hours before the event.</li>
            <li className="mb-2">Seating arrangements are predetermined to facilitate group interaction and dining experience.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">5.2 Participant Responsibilities</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Arrive at the designated location at least 10 minutes before the scheduled start time.</li>
            <li className="mb-2">Bring valid identification and your digital or printed ticket confirmation.</li>
            <li className="mb-2">Notify us of any dietary restrictions or allergies at least one week in advance.</li>
            <li className="mb-2">Maintain appropriate behavior and respect other participants and restaurant staff.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">5.3 Menu and Dining Experience</h3>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="mb-2">The menu is pre-selected and curated specifically for the Event.</li>
            <li className="mb-2">Modifications to the menu are limited and must be requested in advance.</li>
            <li className="mb-2">Participants are encouraged to try all dishes but may opt out of specific items.</li>
            <li className="mb-2">Photography of food is permitted unless otherwise specified by the restaurant.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Liability and Disclaimers</h2>
          <p className="text-gray-600 mb-4">
            While we strive to provide the best possible experience, participants must understand and acknowledge certain limitations and risks:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">6.1 General Liability</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">TryEverything is not responsible for any incidents, accidents, or interactions that occur at the restaurant.</li>
            <li className="mb-2">The responsibility for paying for additional items, including beverages not covered by the Event ticket, lies solely with the Participant.</li>
            <li className="mb-2">We are not liable for any personal property loss or damage during Events.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">6.2 Restaurant Partner Liability</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Our restaurant partners maintain their own liability insurance and safety protocols.</li>
            <li className="mb-2">Any claims regarding the restaurant premises or service should be directed to the restaurant.</li>
            <li className="mb-2">We act as a facilitator and are not responsible for the restaurant's operations.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">6.3 Force Majeure</h3>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="mb-2">We are not liable for failures or delays resulting from circumstances beyond our reasonable control.</li>
            <li className="mb-2">This includes natural disasters, government actions, or public health emergencies.</li>
            <li className="mb-2">In such cases, we will make reasonable efforts to reschedule Events or provide refunds.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Group Modifications and Special Arrangements</h2>
          <p className="text-gray-600 mb-4">
            To maintain the quality of our Events and accommodate various circumstances, we reserve certain rights regarding group arrangements:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">7.1 Group Composition</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">For the smooth operation of the experience, TryEverything reserves the right to modify group compositions up to 1 hour before the event.</li>
            <li className="mb-2">We strive to maintain balanced group dynamics and may adjust seating arrangements accordingly.</li>
            <li className="mb-2">Special seating requests will be considered but cannot be guaranteed.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">7.2 Special Arrangements</h3>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="mb-2">Requests for special arrangements must be made at least 72 hours before the Event.</li>
            <li className="mb-2">We will make reasonable efforts to accommodate special requests but cannot guarantee all accommodations.</li>
            <li className="mb-2">Additional charges may apply for certain special arrangements.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">8. Assumption of Risk and Participant Responsibilities</h2>
          <p className="text-gray-600 mb-4">
            By participating in our Events, you acknowledge and accept certain inherent risks:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">8.1 Dining Risks</h3>
          <p className="text-gray-600 mb-4">
            By participating in an Event, you voluntarily assume all risks associated with dining, including but not limited to:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Allergic reactions or adverse effects caused by food.</li>
            <li className="mb-2">Risks inherent to restaurant settings, such as accidents or injuries.</li>
            <li className="mb-2">Alcohol consumption, which is solely the responsibility of the Participant.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">8.2 Health and Safety</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Participants must follow all health and safety protocols established by TryEverything and the restaurant.</li>
            <li className="mb-2">You agree to not attend if you have any communicable illnesses or symptoms.</li>
            <li className="mb-2">You must inform us of any medical conditions that may affect your participation.</li>
          </ul>

          <p className="text-gray-600 mt-4">
            You acknowledge that participation is entirely at your own risk, and TryEverything disclaims all liability for any claims arising from these risks.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Food Allergies and Dietary Restrictions</h2>
          <p className="text-gray-600 mb-4">
            We take food safety seriously and work with our restaurant partners to accommodate dietary needs:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Notification Requirements</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Participants must inform TryEverything of any food allergies or dietary restrictions no later than 1 week prior to the Event.</li>
            <li className="mb-2">Efforts will be made to communicate this information to the hosting restaurant, but TryEverything cannot guarantee the avoidance of allergens or accommodation of specific dietary preferences.</li>
            <li className="mb-2">For severe allergies, please contact us directly to discuss accommodation possibilities.</li>
            <li className="mb-2">Late notifications of dietary restrictions may not be accommodated.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Restaurant Responsibilities</h3>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="mb-2">Our restaurant partners make reasonable efforts to accommodate dietary restrictions.</li>
            <li className="mb-2">Cross-contamination may occur in restaurant kitchens.</li>
            <li className="mb-2">Participants with severe allergies participate at their own risk.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Alcohol Policy</h2>
          <p className="text-gray-600 mb-4">
            Our alcohol policy is designed to ensure responsible consumption and clear understanding of liability:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Service and Payment</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Alcohol is not provided, arranged, or paid for by TryEverything.</li>
            <li className="mb-2">Participants may choose to purchase alcoholic beverages directly from the hosting restaurant.</li>
            <li className="mb-2">All alcohol purchases and consumption are subject to the restaurant's policies and local laws.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Liability</h3>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="mb-2">TryEverything assumes no responsibility for the consequences of alcohol consumption, including intoxication, accidents, or third-party claims.</li>
            <li className="mb-2">Participants are expected to drink responsibly and arrange safe transportation.</li>
            <li className="mb-2">The restaurant reserves the right to refuse service to anyone showing signs of intoxication.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Non-Transferability of Tickets</h2>
          <p className="text-gray-600 mb-4">
            To maintain the integrity of our Events and ensure participant safety, we maintain strict ticket transfer policies:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Transfer Restrictions</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Tickets are non-transferable unless explicitly permitted by TryEverything.</li>
            <li className="mb-2">Unauthorized transfers will result in invalidated tickets without a refund.</li>
            <li className="mb-2">Resale of tickets is strictly prohibited.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Transfer Requests</h3>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="mb-2">Transfer requests must be submitted at least 48 hours before the Event.</li>
            <li className="mb-2">The new participant must agree to these Terms of Service.</li>
            <li className="mb-2">Administrative fees may apply to approved transfers.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Changes to Events and Terms</h2>
          <p className="text-gray-600 mb-4">
            We reserve the right to make necessary changes to ensure the quality and viability of our Events:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Event Modifications</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">TryEverything reserves the right to modify Event details, including but not limited to menu items, timing, and group arrangements.</li>
            <li className="mb-2">Significant changes will be communicated to participants as soon as possible.</li>
            <li className="mb-2">Minor modifications may be made without prior notice.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Event Cancellations or Rescheduling</h3>
          <p className="text-gray-600 mb-4">
            TryEverything reserves the right to cancel or reschedule Events due to unforeseen circumstances, including but not limited to:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Natural disasters or severe weather conditions</li>
            <li className="mb-2">Operational issues at the restaurant</li>
            <li className="mb-2">Insufficient participation</li>
            <li className="mb-2">Public health concerns or government mandates</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Changes to Terms</h3>
          <p className="text-gray-600">
            These Terms may be updated at any time without prior notice. Any modifications will be effective upon posting on our website, and continued participation in Events will constitute acceptance of the revised Terms.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Media Release</h2>
          <p className="text-gray-600 mb-4">
            We document our Events to share the experience and promote our services:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Content Creation and Usage</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">By attending an Event, you consent to being photographed, filmed, or otherwise recorded for promotional purposes.</li>
            <li className="mb-2">Content may be used on our website, social media, or marketing materials.</li>
            <li className="mb-2">We strive to represent participants in a respectful and positive manner.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Opt-Out Options</h3>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="mb-2">Participants who do not wish to appear in such media must notify TryEverything in writing at least 24 hours prior to the Event.</li>
            <li className="mb-2">We will make reasonable efforts to exclude opt-out participants from media coverage.</li>
            <li className="mb-2">Complete exclusion from group photos may not be possible in all cases.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Governing Law and Dispute Resolution</h2>
          <p className="text-gray-600 mb-4">
            These terms are governed by and construed in accordance with Canadian law:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Jurisdiction</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">These Terms are governed by the laws of Canada.</li>
            <li className="mb-2">You agree to submit to the exclusive jurisdiction of the Canadian courts.</li>
            <li className="mb-2">The laws of Ontario will apply to any disputes not covered by federal law.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Dispute Resolution Process</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Any disputes arising from these Terms or participation in Events shall be resolved through binding arbitration in Canada, in accordance with the local and/or federal laws that apply.</li>
            <li className="mb-2">Before initiating formal proceedings, parties agree to attempt resolution through direct negotiation.</li>
            <li className="mb-2">The arbitration shall be conducted in English and the decision shall be final and binding.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Legal Fees</h3>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="mb-2">Each party shall bear its own costs in any dispute resolution process.</li>
            <li className="mb-2">The arbitrator may award reasonable legal fees to the prevailing party.</li>
            <li className="mb-2">Filing fees for arbitration shall be split equally unless otherwise determined by the arbitrator.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Contact Information</h2>
          <p className="text-gray-600 mb-4">
            We are committed to addressing your questions and concerns promptly:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">General Inquiries</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">For questions, concerns, or additional information regarding these Terms, please email us at tryeverything.mgmt@gmail.com</li>
            <li className="mb-2">We strive to respond to all inquiries within 1-2 business days.</li>
            <li className="mb-2">For urgent matters related to upcoming Events, please indicate "URGENT" in your email subject line.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Business Hours</h3>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="mb-2">Our support team is available Monday through Friday, 9:00 AM to 5:00 PM Eastern Time.</li>
            <li className="mb-2">Emergency contact information will be provided to registered participants before Events.</li>
            <li className="mb-2">Response times may be longer during weekends and Canadian holidays.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Acknowledgment and Agreement</h2>
          <p className="text-gray-600 mb-4">
            Your participation in our Events indicates your understanding and acceptance of these terms:
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Acceptance of Terms</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">By registering for or participating in a TryEverything Event, you acknowledge that you have read, understood, and agreed to these Terms of Service.</li>
            <li className="mb-2">You further release TryEverything from all liability to the fullest extent permitted by law.</li>
            <li className="mb-2">Your continued use of our services constitutes ongoing acceptance of these terms.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Electronic Communications</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">You consent to receive communications from us electronically.</li>
            <li className="mb-2">We may communicate with you by email or by posting notices on our website.</li>
            <li className="mb-2">You agree that all agreements, notices, disclosures, and other communications we provide electronically satisfy any legal requirement that such communications be in writing.</li>
          </ul>

          <p className="text-gray-600 italic mt-6">
            These Terms of Service were last updated on December 11th, 2024. By continuing to use our services after any changes to these Terms, you agree to be bound by the revised Terms.
          </p>
        </section>
      </div>
    </div>
  );
}