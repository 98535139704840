import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { AuthGuard } from '../components/auth/AuthGuard';
import { HomePage } from '../pages/HomePage';
import { EventsPage } from '../pages/EventsPage';
import { EventDetailsPage } from '../pages/EventDetailsPage';
import { RestaurantsPage } from '../pages/RestaurantsPage';
import { RestaurantDetailsPage } from '../pages/RestaurantDetailsPage';
import { BecomeHostPage } from '../pages/BecomeHostPage';
import { BookingsPage } from '../pages/profile/BookingsPage';
import { SettingsPage } from '../pages/profile/SettingsPage';
import { LoginPage } from '../pages/LoginPage';
import { SignupPage } from '../pages/SignupPage';
import { AboutPage } from '../pages/AboutPage';
import { ContactPage } from '../pages/ContactPage';
import { PrivacyPage } from '../pages/PrivacyPage';
import { TermsPage } from '../pages/TermsPage';
import { CookiesPage } from '../pages/CookiesPage';
import { CheckoutSuccessPage } from '../pages/CheckoutSuccessPage';
import { GoogleCallback } from '../pages/auth/GoogleCallback';

export const routes = [
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/events',
    element: <EventsPage />
  },
  {
    path: '/events/:id',
    element: <EventDetailsPage />
  },
  {
    path: '/restaurants',
    element: <RestaurantsPage />
  },
  {
    path: '/restaurants/:restaurantId',
    element: <RestaurantDetailsPage />
  },
  {
    path: '/partner',
    element: <BecomeHostPage />
  },
  {
    path: '/bookings',
    element: (
      <AuthGuard>
        <BookingsPage />
      </AuthGuard>
    )
  },
  {
    path: '/settings',
    element: (
      <AuthGuard>
        <SettingsPage />
      </AuthGuard>
    )
  },
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/signup',
    element: <SignupPage />
  },
  {
    path: '/about',
    element: <AboutPage />
  },
  {
    path: '/contact',
    element: <ContactPage />
  },
  {
    path: '/privacy',
    element: <PrivacyPage />
  },
  {
    path: '/terms',
    element: <TermsPage />
  },
  {
    path: '/cookies',
    element: <CookiesPage />
  },
  {
    path: '/checkout/success',
    element: <CheckoutSuccessPage />
  },
  {
    path: '/auth/callback/google',
    element: <GoogleCallback />
  }
];

export function renderRoutes() {
  return routes.map(({ path, element }) => (
    <Route key={path} path={path} element={element} />
  ));
}