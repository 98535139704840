import { databases, DATABASE_ID, COLLECTIONS, Query, ID } from '../lib/appwrite';
import type { Booking } from '../types/database';

class BookingService {
  async getUserBookings(userId: string): Promise<Booking[]> {
    try {
      const response = await databases.listDocuments(
        DATABASE_ID,
        COLLECTIONS.BOOKINGS,
        [
          Query.equal('userId', userId),
          Query.orderDesc('$createdAt')
        ]
      );
      return response.documents as Booking[];
    } catch (error) {
      console.error('Get user bookings error:', error);
      throw error;
    }
  }

  async createBooking(
    eventId: string,
    userId: string,
    quantity: number,
    totalAmount: number,
    stripeSessionId: string
  ): Promise<Booking> {
    try {
      const now = new Date().toISOString();
      const response = await databases.createDocument(
        DATABASE_ID,
        COLLECTIONS.BOOKINGS,
        ID.unique(),
        {
          eventId,
          userId,
          quantity,
          totalAmount,
          status: 'pending',
          stripeSessionId,
          createdAt: now,
          updatedAt: now
        }
      );
      return response as Booking;
    } catch (error) {
      console.error('Create booking error:', error);
      throw error;
    }
  }

  async updateBooking(bookingId: string, bookingData: Partial<Booking>): Promise<Booking> {
    try {
      const response = await databases.updateDocument(
        DATABASE_ID,
        COLLECTIONS.BOOKINGS,
        bookingId,
        {
          ...bookingData,
          updatedAt: new Date().toISOString()
        }
      );
      return response as Booking;
    } catch (error) {
      console.error('Update booking error:', error);
      throw error;
    }
  }

  async deleteBooking(bookingId: string): Promise<void> {
    try {
      await databases.deleteDocument(
        DATABASE_ID,
        COLLECTIONS.BOOKINGS,
        bookingId
      );
    } catch (error) {
      console.error('Delete booking error:', error);
      throw error;
    }
  }

  async getBooking(bookingId: string): Promise<Booking> {
    try {
      const response = await databases.getDocument(
        DATABASE_ID,
        COLLECTIONS.BOOKINGS,
        bookingId
      );
      return response as Booking;
    } catch (error) {
      console.error('Get booking error:', error);
      throw error;
    }
  }
}

export const bookingService = new BookingService(); 