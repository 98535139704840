import React from 'react';
import { Link } from 'react-router-dom';
import { clsx } from 'clsx';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'outline';
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

interface ButtonLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
  variant?: 'primary' | 'secondary' | 'outline';
  className?: string;
}

const baseStyles = {
  primary: 'bg-gray-900 text-white hover:bg-gray-800 shadow-sm',
  secondary: 'bg-gray-100 text-gray-900 hover:bg-gray-200',
  outline: 'border-2 border-gray-900 text-gray-900 hover:bg-gray-50'
};

const sizeStyles = {
  sm: 'px-3 py-1.5 text-sm',
  md: 'px-4 py-2',
  lg: 'px-6 py-3 text-lg'
};

export function Button({ 
  variant = 'primary', 
  size = 'md',
  className, 
  ...props 
}: ButtonProps) {
  return (
    <button
      className={clsx(
        'font-medium rounded-full transition-colors inline-flex items-center justify-center',
        baseStyles[variant],
        sizeStyles[size],
        className
      )}
      {...props}
    />
  );
}

export function ButtonLink({ to, variant = 'primary', className = '', ...props }: ButtonLinkProps) {
  const baseStyles = 'inline-flex items-center justify-center px-4 py-2 border text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900';
  
  const variantStyles = {
    primary: 'border-transparent text-white bg-gray-900 hover:bg-gray-800',
    secondary: 'border-gray-900 text-gray-900 bg-white hover:bg-gray-50',
    outline: 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
  };

  return (
    <Link
      to={to}
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
      {...props}
    />
  );
}