import React, { useState, useEffect } from 'react';
import { useParams, Navigate, Link } from 'react-router-dom';
import { MapPin, Calendar, Users, MessageCircle, Mail, Facebook, Instagram, Share2 } from 'lucide-react';
import { format } from 'date-fns';
import { Card } from '../components/ui/Card';
import { Button } from '../components/ui/Button';
import { useEvent } from '../hooks/useEvent';
import { useAuth } from '../hooks/useAuth';
import { stripeService } from '../services/stripe';
import { useRelatedEvents } from '../hooks/useRelatedEvents';
import { EventCard } from '../components/events/EventCard';
import type { Event, User } from '../types/database';
import { useRestaurant } from '../hooks/useRestaurant';
import { WaitlistCard } from '../components/ui/WaitlistCard';
import { useUserEventBooking } from '../hooks/useUserEventBooking';

function getMapLinks(lat: number | undefined, long: number | undefined, address: string | undefined) {
  if (!lat || !long || !address) return null;
  const encodedAddress = encodeURIComponent(address);
  return {
    google: `https://www.google.com/maps/search/?api=1&query=${lat},${long}`,
    apple: `http://maps.apple.com/?q=${encodedAddress}&ll=${lat},${long}`
  };
}

export function EventDetailsPage() {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth() as { user: User | null };
  const { event, loading, error: eventError } = useEvent(id!);
  const { booking: userBooking } = useUserEventBooking(user?.$id, id);
  const [quantity, setQuantity] = useState(1);
  const [bookingInProgress, setBookingInProgress] = useState(false);
  const { relatedEvents, loading: relatedEventsLoading } = useRelatedEvents(event?.city, event?.$id);
  const { restaurant } = useRestaurant(event?.restaurantId, false);
  const [checkoutError, setCheckoutError] = useState<string | null>(null);

  console.log('Event Details:', {
    id,
    event,
    loading,
    error: eventError,
    restaurantId: event?.restaurantId
  });

  // Handle post-login checkout
  useEffect(() => {
    if (loading || !event) return;

    const params = new URLSearchParams(window.location.search);
    const action = params.get('action');
    const savedQuantity = params.get('quantity');
    
    console.log('Post-login checkout check:', { action, savedQuantity, user });
    
    if (user && action === 'checkout' && savedQuantity) {
      const qty = parseInt(savedQuantity, 10);
      setQuantity(qty);
      
      console.log('Processing post-login checkout:', {
        eventId: event.$id,
        userId: user.userId,
        quantity: qty,
        price: event.price
      });

      setBookingInProgress(true);
      stripeService.createCheckoutSession({
        eventId: event.$id,
        userId: user.userId,
        quantity: qty,
        unitAmount: event.price * 100,
        title: event.title,
        imageUrl: event.imageUrl,
        date: event.date,
        time: event.time,
        restaurant: event.restaurant || {
          name: 'Restaurant details loading...',
          address: 'Address loading...'
        }
      })
        .then(({ url }) => {
          console.log('Checkout session created:', { url });
          if (url) {
            window.location.href = url;
          } else {
            console.error('No URL returned from checkout session');
          }
        })
        .catch((error) => {
          console.error('Checkout session creation failed:', error);
        })
        .finally(() => {
          setBookingInProgress(false);
          // Clean up URL
          const newUrl = window.location.pathname;
          window.history.replaceState({}, '', newUrl);
        });
    }
  }, [user, event, loading]);

  const handleBooking = async () => {
    if (!user) {
      const returnTo = encodeURIComponent(
        `${window.location.pathname}?quantity=${quantity}&action=checkout`
      );
      window.location.href = `/login?returnTo=${returnTo}`;
      return;
    }

    if (!event) return;

    console.log('Starting booking process:', {
      eventId: event.$id,
      userId: user.userId,
      quantity,
      price: event.price,
      restaurant: event.restaurant
    });

    setCheckoutError(null);
    setBookingInProgress(true);
    try {
      const { url } = await stripeService.createCheckoutSession({
        eventId: event.$id,
        userId: user.userId,
        quantity,
        unitAmount: event.price * 100,
        title: event.title,
        imageUrl: event.imageUrl,
        date: event.date,
        time: event.time,
        restaurant: event.restaurant || {
          name: 'Restaurant details loading...',
          address: 'Address loading...'
        }
      });

      console.log('Checkout session created:', { url });

      if (url) {
        window.location.href = url;
      } else {
        throw new Error('No URL returned from checkout session');
      }
    } catch (err) {
      console.error('Checkout session creation failed:', err);
      setCheckoutError(err instanceof Error ? err.message : 'Payment processing failed');
    } finally {
      setBookingInProgress(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  if (eventError) {
    console.error('Event load error:', eventError);
    return <Navigate to="/events" replace />;
  }

  if (!event) {
    console.error('No event found');
    return <Navigate to="/events" replace />;
  }

  const shareLinks = [
    {
      name: 'iMessage',
      icon: <MessageCircle className="h-5 w-5" />,
      url: `sms:&body=Check out ${event.title} on Try Everything: ${window.location.href}`
    },
    {
      name: 'WhatsApp',
      icon: (
        <svg viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5">
          <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347"/>
        </svg>
      ),
      url: `https://wa.me/?text=${encodeURIComponent(`Check out ${event.title} on Try Everything: ${window.location.href}`)}`
    },
    {
      name: 'Instagram',
      icon: <Instagram className="h-5 w-5" />,
      url: `https://www.instagram.com/share?url=${encodeURIComponent(window.location.href)}`
    },
    {
      name: 'Facebook',
      icon: <Facebook className="h-5 w-5" />,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`
    },
    {
      name: 'Email',
      icon: <Mail className="h-5 w-5" />,
      url: `mailto:?subject=${encodeURIComponent(`Check out ${event.title} on Try Everything`)}&body=${encodeURIComponent(`I found this amazing event: ${window.location.href}`)}`
    }
  ];

  const availableTickets = event ? event.capacity - (event.registered || 0) : 0;
  const isSoldOut = availableTickets <= 0;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-8">
          {/* Hero Image */}
          <div className="mb-8">
            <img 
              src={event.imageUrl} 
              alt={event.title}
              className="w-full aspect-[16/9] object-cover rounded-lg"
            />
          </div>
          
          {/* Event Details */}
          <div className="mb-8">
            <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">
              {event.title}
            </h1>
            
            <div className="flex flex-wrap gap-4 text-gray-600 mb-6">
              <div className="flex items-center">
                <Calendar className="h-5 w-5 mr-2 flex-shrink-0" />
                <span>{format(new Date(event.date), 'MMMM d, yyyy')} at {event.time}</span>
              </div>
              
              <div className="flex items-center">
                <Users className="h-5 w-5 mr-2 flex-shrink-0" />
                <span>{event.capacity - event.registered} spots left</span>
              </div>
            </div>

            <p className="text-gray-600 text-lg">
              {event.description}
            </p>
          </div>

          {/* Restaurant Information */}
          {restaurant && (
            <div className="mb-8">
              <Card>
                <div className="p-6">
                  <h2 className="text-xl font-semibold mb-4">Restaurant Information</h2>
                  <Link 
                    to={`/restaurants/${restaurant.restaurantId}`}
                    className="text-2xl text-purple-600 hover:text-purple-700 font-medium mb-4 block"
                  >
                    {restaurant.name}
                  </Link>
                  <div className="space-y-4 text-gray-600">
                    <p className="text-lg">{restaurant.description}</p>
                    
                    <div className="flex flex-wrap gap-x-8 gap-y-2">
                      <div className="flex items-center">
                        <MapPin className="h-5 w-5 mr-2" />
                        <span>{restaurant.address}</span>
                      </div>
                      
                      {restaurant.phone && (
                        <div>
                          <a 
                            href={`tel:${restaurant.phone}`}
                            className="hover:text-purple-600"
                          >
                            {restaurant.phone}
                          </a>
                        </div>
                      )}
                      
                      <div className="flex items-center gap-4">
                        <span>
                          <span className="text-yellow-400">★</span>
                          <span className="ml-1">{restaurant.rating.toFixed(1)}</span>
                        </span>
                        <span>{restaurant.cuisine}</span>
                        <span>{restaurant.priceRange}</span>
                      </div>
                    </div>

                    {restaurant.website && (
                      <a 
                        href={restaurant.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block text-purple-600 hover:text-purple-700 mt-2"
                      >
                        Visit Website →
                      </a>
                    )}
                  </div>
                </div>
              </Card>
            </div>
          )}

          {/* Map */}
          {restaurant?.lat && restaurant.long && restaurant.address && (
            <div className="mb-8">
              <Card className="overflow-hidden">
                <div className="h-[300px] relative">
                  <iframe
                    src={`https://www.openstreetmap.org/export/embed.html?bbox=${(restaurant.long - 0.01).toFixed(6)},${(restaurant.lat - 0.01).toFixed(6)},${(restaurant.long + 0.01).toFixed(6)},${(restaurant.lat + 0.01).toFixed(6)}&layer=mapnik&marker=${restaurant.lat.toFixed(6)},${restaurant.long.toFixed(6)}`}
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    className="absolute inset-0 w-full h-full"
                  ></iframe>
                </div>
                <div className="p-4 flex justify-end gap-4">
                  {getMapLinks(restaurant.lat, restaurant.long, restaurant.address) && (
                    <>
                      <a
                        href={getMapLinks(restaurant.lat, restaurant.long, restaurant.address)!.google}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm text-purple-600 hover:text-purple-700 flex items-center gap-1"
                      >
                        <MapPin className="h-4 w-4" />
                        Open in Google Maps
                      </a>
                      <a
                        href={getMapLinks(restaurant.lat, restaurant.long, restaurant.address)!.apple}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm text-purple-600 hover:text-purple-700 flex items-center gap-1"
                      >
                        <MapPin className="h-4 w-4" />
                        Open in Apple Maps
                      </a>
                    </>
                  )}
                </div>
              </Card>
            </div>
          )}
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          {/* Booking Card */}
          <Card className="overflow-hidden">
            <div className="p-6">
              <div className="text-center mb-6">
                <div className="text-4xl font-bold text-gray-900">${event.price}</div>
                <p className="text-gray-600">per person</p>
              </div>

              <div className="space-y-4">
                {userBooking && (
                  <div className="bg-green-50 text-green-700 p-4 rounded-lg mb-4">
                    <p className="font-medium">You're already going! 🎉</p>
                    <p className="text-sm mt-1">You have {userBooking.quantity} {userBooking.quantity === 1 ? 'ticket' : 'tickets'}</p>
                  </div>
                )}

                {isSoldOut ? (
                  <div className="text-center p-4 bg-gray-100 rounded-lg">
                    <h3 className="text-lg font-semibold text-gray-900">Sold Out</h3>
                    <p className="text-gray-600">This event is currently sold out.</p>
                  </div>
                ) : (
                  <>
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-gray-600">
                        {availableTickets} tickets remaining
                      </span>
                      <span className="text-sm text-gray-600">
                        ${event.price} per person
                      </span>
                    </div>
                    
                    {/* Quantity selector and booking button */}
                    <div className="flex items-center justify-between border rounded-lg p-2">
                      <Button
                        variant="outline"
                        onClick={() => setQuantity(q => Math.max(1, q - 1))}
                        disabled={quantity <= 1}
                        className="p-2"
                      >
                        -
                      </Button>
                      <input
                        type="number"
                        min="1"
                        max={availableTickets}
                        value={quantity}
                        onChange={(e) => {
                          const val = parseInt(e.target.value) || 1;
                          setQuantity(Math.min(Math.max(1, val), availableTickets));
                        }}
                        className="w-20 text-center"
                        aria-label="Ticket quantity"
                        title="Number of tickets"
                      />
                      <Button
                        variant="outline"
                        onClick={() => setQuantity(q => Math.min(q + 1, availableTickets))}
                        disabled={quantity >= availableTickets}
                        className="p-2"
                      >
                        +
                      </Button>
                    </div>

                    <Button
                      onClick={handleBooking}
                      disabled={bookingInProgress || quantity > availableTickets}
                      className="w-full"
                    >
                      {bookingInProgress ? (
                        <div className="flex items-center justify-center gap-2">
                          <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                          Processing...
                        </div>
                      ) : userBooking ? (
                        'Book More Tickets'
                      ) : (
                        `Pay ${quantity} × $${event.price}`
                      )}
                    </Button>
                  </>
                )}

                {checkoutError && (
                  <div className="mt-4 p-3 bg-red-50 text-red-600 rounded-md text-sm">
                    {checkoutError}
                  </div>
                )}

                <p className="mt-4 text-sm text-gray-600 text-center">
                  Please note: We do not offer refunds. If you cannot attend, we suggest gifting your ticket to a friend!
                </p>
              </div>
            </div>
          </Card>

          {/* Share Card */}
          <Card className="overflow-hidden">
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="font-medium text-gray-900">Share with Friends</h3>
                <Share2 className="h-5 w-5 text-gray-400" />
              </div>
              <div className="grid grid-cols-5 gap-4">
                {shareLinks.map(platform => (
                  <button
                    key={platform.name}
                    onClick={() => window.open(platform.url, '_blank')}
                    className="flex flex-col items-center gap-2 text-gray-600 hover:text-purple-600 transition-colors"
                  >
                    {platform.icon}
                    <span className="text-xs">{platform.name}</span>
                  </button>
                ))}
              </div>
            </div>
          </Card>

          {/* Add Waitlist Card */}
          <WaitlistCard />

          {/* Related Events */}
          {relatedEvents?.length > 0 && (
            <Card className="overflow-hidden">
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-4">More Events in {event.city}</h2>
                <div className="space-y-4">
                  {relatedEvents.map(relatedEvent => (
                    <EventCard 
                      key={relatedEvent.$id}
                      event={relatedEvent}
                      compact
                    />
                  ))}
                </div>
              </div>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
}