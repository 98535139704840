import { useState, useEffect } from 'react';
import { eventService } from '../services/database';
import type { Event } from '../types/database';

export function useEvents({ city = '', date = '', featured = false }) {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let mounted = true;

    async function fetchEvents() {
      try {
        setLoading(true);
        const data = await eventService.list();
        console.log('Raw events data:', data);

        if (!mounted) return;

        let filteredEvents = data.filter(event => 
          event.status === 'published' && 
          new Date(event.date) >= new Date()
        );

        if (city) {
          filteredEvents = filteredEvents.filter(event => event.city === city);
        }

        if (featured) {
          // Group by city and get earliest event from each
          const eventsByCity = filteredEvents.reduce((acc, event) => {
            if (!acc[event.city]) {
              acc[event.city] = [];
            }
            acc[event.city].push(event);
            return acc;
          }, {} as Record<string, Event[]>);

          filteredEvents = Object.values(eventsByCity).map(cityEvents => 
            cityEvents.reduce((earliest, current) => 
              new Date(current.date) < new Date(earliest.date) ? current : earliest
            )
          );
        }

        console.log('Filtered events:', filteredEvents);
        setEvents(filteredEvents);
      } catch (err) {
        console.error('Failed to fetch events:', err);
        if (mounted) {
          setError(err instanceof Error ? err.message : 'Failed to load events');
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    fetchEvents();

    return () => {
      mounted = false;
    };
  }, [city, date, featured]);

  return { events, loading, error };
}