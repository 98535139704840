import React, { useEffect } from 'react';
import { LoginForm } from '../components/auth/LoginForm';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  // Function to handle redirect after authentication
  const handleAuthSuccess = () => {
    const params = new URLSearchParams(location.search);
    const returnTo = params.get('returnTo');
    
    if (returnTo) {
      navigate(decodeURIComponent(returnTo), { replace: true });
    } else {
      navigate('/settings', { replace: true });
    }
  };

  // Handle automatic redirect when user is already logged in
  useEffect(() => {
    if (user) {
      handleAuthSuccess();
    }
  }, [user, location]);

  return <LoginForm onLoginSuccess={handleAuthSuccess} onSignupSuccess={handleAuthSuccess} />;
}