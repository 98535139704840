import { useState, useEffect } from 'react';
import { databases, DATABASE_ID, COLLECTIONS } from '../lib/appwrite';
import type { Restaurant } from '../types/database';
import { Query } from 'appwrite';

export function useRestaurant(id: string | undefined, requireApproved: boolean = true) {
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    console.log('useRestaurant hook called with:', { id, requireApproved });
    
    const fetchRestaurant = async () => {
      if (!id) {
        console.error('useRestaurant: No ID provided');
        setError(new Error('No restaurant ID provided'));
        setLoading(false);
        return;
      }

      try {
        // Query by restaurantId field directly
        console.log('Fetching restaurant with restaurantId:', id);
        const response = await databases.listDocuments(
          DATABASE_ID,
          COLLECTIONS.RESTAURANTS,
          [
            Query.equal('restaurantId', id),
            ...(requireApproved ? [Query.equal('status', 'approved')] : [])
          ]
        );

        console.log('Restaurant fetch response:', response);

        if (response.documents.length === 0) {
          throw new Error(`Restaurant not found with ID: ${id}`);
        }

        setRestaurant(response.documents[0] as Restaurant);
        setError(null);
      } catch (err) {
        console.error('Error fetching restaurant:', { err, id });
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurant();
  }, [id, requireApproved]);

  return { restaurant, loading, error };
} 