import React from 'react';

export function PrivacyPage() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">Privacy Policy for TryEverything</h1>
      
      <div className="prose prose-lg">
        <p className="text-gray-600 mb-8">
          Effective Date: December 11th, 2024
        </p>

        <p className="text-gray-600 mb-8">
          At TryEverything, we are committed to protecting and respecting your privacy. This Privacy Policy outlines how we collect, use, store, and protect your personal information when you use our platform or participate in our Events.
        </p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. What Information Do We Collect About You?</h2>
          <p className="text-gray-600 mb-4">We collect the following information about you:</p>
          
          <h3 className="text-xl font-semibold text-gray-900 mb-3">2. Personal Information You Provide Directly:</h3>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">When you register for Events or purchase tickets, we collect your name, email address, phone number, and payment details.</li>
            <li className="mb-2">When you notify us of dietary restrictions, food allergies, or preferences, we collect that information to accommodate your needs.</li>
            <li className="mb-2">If you contact us directly (e.g., via email or contact forms), we collect the content of your message and any additional details you provide.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">3. Automatically Collected Information:</h3>
          <p className="text-gray-600 mb-4">
            We collect technical information such as your IP address, browser type, and usage data through cookies and similar tracking technologies to improve your experience on our website.
          </p>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">4. Third-Party Information:</h3>
          <p className="text-gray-600">
            If you use a third-party service (e.g., social media or payment processors) to interact with our platform, we may collect information from those services as permitted by their privacy policies.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. How Do We Use This Information?</h2>
          <p className="text-gray-600 mb-4">We collect and process your personal information to:</p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Manage your account and process Event registrations.</li>
            <li className="mb-2">Communicate Event details, including updates and reminders.</li>
            <li className="mb-2">Address dietary restrictions and food allergies to enhance your Event experience.</li>
            <li className="mb-2">Improve our services by analyzing technical data and usage patterns.</li>
            <li className="mb-2">Send marketing communications about our upcoming Events or related services, if you have consented to receive them.</li>
            <li className="mb-2">Respond to inquiries, requests, or feedback you provide.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-900 mb-3">Legal Basis for Processing Your Information</h3>
          <p className="text-gray-600 mb-4">We process your personal information under the following legal bases:</p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Contractual Necessity: To provide the services you request, such as managing your Event registration and communicating important details.</li>
            <li className="mb-2">Consent: For optional marketing communications, which you can opt out of at any time.</li>
            <li className="mb-2">Legitimate Interests: To improve our platform, prevent fraud, and protect the security of our users.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Sharing Your Information</h2>
          <p className="text-gray-600 mb-4">We do not sell your personal information. However, we may share your information with:</p>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="mb-2">Service Providers: Trusted third parties that assist in payment processing, email communication, website hosting, or analytics.</li>
            <li className="mb-2">Restaurants and Event Partners: Necessary information (e.g., dietary restrictions) is shared to facilitate your participation in Events.</li>
            <li className="mb-2">Legal and Regulatory Authorities: If required to comply with applicable laws or protect our legal rights.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. How Do We Protect Your Information?</h2>
          <p className="text-gray-600 mb-4">
            We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. These include:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Encrypted communication (e.g., SSL/TLS) for data transmitted via our website.</li>
            <li className="mb-2">Access controls to restrict internal access to personal data.</li>
            <li className="mb-2">Regular monitoring of our systems for vulnerabilities.</li>
          </ul>
          <p className="text-gray-600">
            However, no system can be entirely secure. By using our platform, you acknowledge and accept the inherent risks of transmitting data online.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Your Rights and Choices</h2>
          <p className="text-gray-600 mb-4">You have the following rights regarding your personal information:</p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li className="mb-2">Access and Portability: Request a copy of the personal information we hold about you.</li>
            <li className="mb-2">Correction: Request that we update or correct inaccurate information.</li>
            <li className="mb-2">Deletion: Request that we delete your personal data, subject to legal or contractual obligations.</li>
            <li className="mb-2">Marketing Preferences: Opt-out of marketing communications by following the instructions provided in our emails or contacting us directly.</li>
          </ul>
          <p className="text-gray-600">
            To exercise these rights, please contact us at tryeverything.mgmt@gmail.com.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Cookies and Tracking Technologies</h2>
          <p className="text-gray-600 mb-4">
            We use cookies and similar technologies to enhance your experience on our website. Cookies are small text files stored on your device that help us analyze traffic, remember preferences, and improve our services.
          </p>
          <p className="text-gray-600">
            You can control or disable cookies through your browser settings. However, disabling cookies may impact your ability to use certain features of our website.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Data Retention</h2>
          <p className="text-gray-600">
            We retain your personal information only as long as necessary to fulfill the purposes outlined in this Privacy Policy, including legal, accounting, or reporting requirements.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Changes to Our Privacy Policy</h2>
          <p className="text-gray-600">
            We regularly review and may update this Privacy Policy to reflect changes in our practices, technologies, or legal requirements. Updates will be posted on our website with the revised effective date.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">How to Contact Us</h2>
          <p className="text-gray-600">
            If you have any questions about this Privacy Policy or wish to exercise your rights, please contact us at tryeverything.mgmt@gmail.com.
          </p>
        </section>

        <section className="mb-8">
          <p className="text-gray-600 italic">
            By using TryEverything's platform or participating in our Events, you acknowledge that you have read and understood this Privacy Policy and agree to the collection and use of your information as described.
          </p>
        </section>
      </div>
    </div>
  );
}